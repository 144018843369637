import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchEquipmentItems from '../../../services/equipment_items/FetchEquipmentItems';
import AlpineButton from '../../../components/AlpineButton';

const emptyEquipment = {
  equipment_item_id: null, equipmentName: '', count: 0,
};

const WorklogEquipmentSection = ({ formik, readOnly = false }) => {
  const [equipmentItems, setEquipmentItems] = useState([]);

  const populateEquipmentItems = (callback) => {
    fetchEquipmentItems((response) => {
      setEquipmentItems(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateEquipmentItems();
  }, []);
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h6">
          Equipment
        </Typography>
      </Box>
      <Box>
        <FieldArray
          name="worklog_equipment_items"
          render={
            (arrayHelpers) => (
              <Grid container width="100%">
                {
                  formik.values.worklog_equipment_items.map((labor, index) => (
                    <>
                      <Grid item md={3} width="100%" mb={2}>
                        <MuiFormikAutocomplete
                          name={`worklog_equipment_items.${index}.equipment_item_id`}
                          label="Equipment Name"
                          formik={formik}
                          options={equipmentItems}
                          freeSolo
                          value={labor.equipment_item_id}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid paddingX={1} item md={3} width="100%" mb={2}>
                        <MuiFormikTextField
                          name={`worklog_equipment_items.${index}.count`}
                          label="Units"
                          type="number"
                          formik={formik}
                          options={equipmentItems}
                          value={labor.hours}
                          disabled={readOnly}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={2} width="100%">
                        {!formik.values.worklog_equipment_items[index]?.id && (
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                        )}
                      </Grid>
                      <Grid item md={4} />
                    </>
                  ))
                }
                {!readOnly && (
                  <Grid item md={3} mt={1} width="100%" mb={2}>
                    <AlpineButton
                      variant="contained"
                      fullWidth
                      startIcon={<ControlPointIcon />}
                      size="small"
                      onClick={() => arrayHelpers.push(emptyEquipment)}
                    >
                      Add Equipment
                    </AlpineButton>
                  </Grid>
                )}
              </Grid>
            )
          }
        />
      </Box>
    </Box>
  );
};

export default WorklogEquipmentSection;
export { emptyEquipment };
