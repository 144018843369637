import {
  Box, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import Signature from '../../../components/Signature';
import moneyFormat from '../../../services/MoneyFormat';
import ApproveTicketDialog from '../components/ApproveTicketDialog';
import { calculateEquipmentRow, calculateEquipmentSubsection } from '../components/EquipmentSection';
import { calculateLaborRow, calculateLaborSubtotal } from '../components/LaborSection';
import { calculateMaterialRow, calculateMaterialSubtotal } from '../components/MaterialSection';
import ReviseTicketDialog from '../components/ReviseTicketDialog';
import { calculateMarkup, calculateSubtotal } from '../components/TicketForm';

const Default = ({ ticket }) => {
  const [approveTicketOpen, setApproveTicketOpen] = useState(false);
  const [reviseTicketOpen, setReviseTicketOpen] = useState(false);

  const history = useHistory();

  return (
    <Box>
      <Box mb={3}>
        <Grid container>
          <Grid item md={6} width="50%">
            <Box pr={3}>
              <SimpleDataDisplay label="Job Name" value={ticket.project_name} />
            </Box>
            <Box pr={3}>
              <SimpleDataDisplay label="Company Name" value={ticket.organization_name} />
            </Box>
            <Box>
              <SimpleDataDisplay label="Date" value={ticket.work_completed_on} />
            </Box>
          </Grid>
          <Grid item md={6} width="50%" textAlign="right">
            <Box>
              <SimpleDataDisplay label="Sub Rep" value={ticket.created_by_name} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <Box textAlign="center">
          <Typography variant="h6" style={{ textDecoration: 'underline' }}>
            Description of Work
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {ticket.description}
          </Typography>
        </Box>
      </Box>
      <Box mb={5}>
        <Box textAlign="center">
          <Typography variant="h6">
            Labor
          </Typography>
        </Box>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="30%"><b>Description</b></TableCell>
                <TableCell width="10%">#</TableCell>
                <TableCell width="20%">Units</TableCell>
                {!ticket.hide_financials && (
                <>
                  <TableCell width="20%">$/HR</TableCell>
                  <TableCell width="20%">Total</TableCell>
                </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ticket.ticket_labor_types.map((laborType, index) => (
                  <TableRow>
                    <TableCell>{ticket.labor_types[index]?.name}</TableCell>
                    <TableCell>{laborType.worker_count}</TableCell>
                    <TableCell>{laborType.hours}</TableCell>
                    {!ticket.hide_financials && (
                    <>
                      <TableCell>
                        {moneyFormat(laborType.hourly_rate)}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(calculateLaborRow(laborType))}
                      </TableCell>
                    </>
                    )}
                  </TableRow>
                ))
              }
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                {!ticket.hide_financials && (
                <>
                  <TableCell style={{ borderBottom: 'none' }}><b>Labor Subtotal</b></TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {moneyFormat(calculateLaborSubtotal(ticket.ticket_labor_types))}
                  </TableCell>
                </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box mb={5}>
        <Box textAlign="center">
          <Typography variant="h6">
            Material
          </Typography>
        </Box>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="30%"><b>Description</b></TableCell>
                <TableCell width="10%">#</TableCell>
                <TableCell width="20%">Units</TableCell>
                {!ticket.hide_financials && (
                <>
                  <TableCell width="20%">$/Unit</TableCell>
                  <TableCell width="20%">Total</TableCell>
                </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ticket.ticket_materials.map((material, index) => (
                  <TableRow>
                    <TableCell>{ticket.materials[index]?.name}</TableCell>
                    <TableCell>{material.unit_count}</TableCell>
                    <TableCell>{material.hours}</TableCell>
                    {!ticket.hide_financials && (
                    <>
                      <TableCell>
                        {moneyFormat(material.cost_per_unit)}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(calculateMaterialRow(material))}
                      </TableCell>
                    </>
                    )}
                  </TableRow>
                ))
              }
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                {!ticket.hide_financials && (
                <>
                  <TableCell style={{ borderBottom: 'none' }}><b>Materials Subtotal</b></TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {moneyFormat(calculateMaterialSubtotal(ticket.ticket_materials))}
                  </TableCell>
                </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box>
        <Box textAlign="center">
          <Typography variant="h6">
            Equipment
          </Typography>
        </Box>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="30%"><b>Description</b></TableCell>
                <TableCell width="10%">#</TableCell>
                <TableCell width="20%">Units</TableCell>
                {!ticket.hide_financials && (
                <>
                  <TableCell width="20%">$/Unit</TableCell>
                  <TableCell width="20%">Total</TableCell>
                </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ticket.ticket_equipment_items.map((equipmentItem, index) => (
                  <TableRow>
                    <TableCell>
                      {ticket.equipment_items[index]?.name}
                      {' '}
                      (
                      {equipmentItem.count}
                      )
                    </TableCell>
                    <TableCell>{equipmentItem.hours}</TableCell>
                    <TableCell>Days</TableCell>
                    {!ticket.hide_financials && (
                    <>
                      <TableCell>
                        {moneyFormat(equipmentItem.hourly_rate)}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(calculateEquipmentRow(equipmentItem))}
                      </TableCell>
                    </>
                    )}
                  </TableRow>
                ))
              }
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                <TableCell style={{ borderBottom: 'none' }} />
                {!ticket.hide_financials && (
                <>
                  <TableCell style={{ borderBottom: 'none' }}><b>Equipment Subtotal</b></TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {moneyFormat(calculateEquipmentSubsection(ticket.ticket_equipment_items))}
                  </TableCell>
                </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box mt={5}>
        {!ticket.hide_financials && (
        <Grid container>
          <Grid item md={8} />
          <Grid item md={4} width="100%">
            <Box textAlign="right">
              <Box pt={5}>
                <SimpleDataDisplay label="Total of Costs" value={`${moneyFormat(calculateSubtotal(ticket))}`} />
              </Box>
              <Box mb={2}>
                <SimpleDataDisplay label="Overhead and Profit" value={`${moneyFormat(calculateMarkup(ticket))}`} />
              </Box>
              <Box>
                <SimpleDataDisplay variant="h5" label="Subtotal w/OH&P" value={`${moneyFormat(calculateMarkup(ticket) + calculateSubtotal(ticket))}`} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        )}
        <Grid container mt={6}>
          <Grid item md={6} width="50%">
            {
              ticket.signatures.map((signature) => (
                <Box textAlign="left" mb={2}>
                  <Stack direction="row" spacing={2} justifyContent="left">
                    <Stack minWidth="150px" textAlign="left">
                      <Stack direction="row">
                        <Signature
                          variant="h6"
                          signatureImageUrl={signature.signed_by_image_url}
                          signedByName={signature.signed_by_name}
                          signedAt={signature.signed_at}
                        />
                      </Stack>
                      <Divider />
                      <Typography variant="h6">
                        <b>{signature.signed_by_account_type}</b>
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))
            }
          </Grid>
          <Grid item md={6} width="50%">
            {
              ticket.signatures.map((signature) => (
                <Box textAlign="right" mb={2}>
                  <Stack direction="row" spacing={2} justifyContent="right">
                    <Stack minWidth="150px">
                      <Typography variant="h6">{format(new Date(signature.signed_at), "MMMM dd, yyyy 'at' hh:mm")}</Typography>
                      <Divider />
                      <Typography variant="h6"><b>Date Signed</b></Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))
            }
          </Grid>
        </Grid>
        {!ticket.completed_at && (
        <Stack className="hide-print" mt={5} mb={2} direction="row" spacing={10}>
          <AlpineButton variant="contained" color="success" onClick={() => setApproveTicketOpen(true)}>
            Approve
          </AlpineButton>
          {/* <AlpineButton disabled variant="contained" color="error">
            Reject
          </AlpineButton> */}
          <AlpineButton variant="contained" color="warning" onClick={() => setReviseTicketOpen(true)}>
            Revise
          </AlpineButton>
        </Stack>
        )}
        <Box className="hide-print">
          <AlpineButton onClick={() => history.goBack()}>
            Back
          </AlpineButton>
        </Box>
      </Box>
      <ApproveTicketDialog
        open={approveTicketOpen}
        handleClose={() => setApproveTicketOpen(false)}
        ticket={ticket}
      />
      <ReviseTicketDialog
        open={reviseTicketOpen}
        handleClose={() => setReviseTicketOpen(false)}
        ticket={ticket}
      />
    </Box>
  );
};

export default Default;
