import {
  Box, Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import React from 'react';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import moneyFormat from '../../../services/MoneyFormat';
import { calculateLaborRow, calculateLaborSubtotal } from '../components/LaborSection';

const Durable = ({ ticket }) => (
  <Box>
    <Box mb={3}>
      <Grid container>
        <Grid md={4}>
          <Box textAlign="center">
            <SimpleDataDisplay label="Date" value={ticket.work_completed_on} />
          </Box>
        </Grid>
        <Grid md={4}>
          <Box textAlign="center">
            <SimpleDataDisplay label="Job Name" value={ticket.project_name} />
          </Box>
        </Grid>
        <Grid md={4}>
          <Box textAlign="center">
            <SimpleDataDisplay label="Job Number" value={ticket.project_id} />
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Box mb={3}>
      <Box>
        <SimpleDataDisplay label="Labor" />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="30%"><b>Description</b></TableCell>
            <TableCell width="10%">#</TableCell>
            <TableCell width="20%">Units</TableCell>
            {!ticket.hide_financials && (
              <>
                <TableCell width="20%">$/HR</TableCell>
                <TableCell width="20%">Total</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {
              ticket.ticket_labor_types.map((laborType, index) => (
                <TableRow>
                  <TableCell>{ticket.labor_types[index].name}</TableCell>
                  <TableCell>{laborType.worker_count}</TableCell>
                  <TableCell>{laborType.hours}</TableCell>
                  {!ticket.hide_financials && (
                  <>
                    <TableCell>
                      {moneyFormat(laborType.hourly_rate)}
                    </TableCell>
                    <TableCell>
                      {moneyFormat(calculateLaborRow(laborType))}
                    </TableCell>
                  </>
                  )}
                </TableRow>
              ))
            }
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            {!ticket.hide_financials && (
              <>
                <TableCell style={{ borderBottom: 'none' }}><b>Labor Subtotal</b></TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                  {moneyFormat(calculateLaborSubtotal(ticket.ticket_labor_types))}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  </Box>
);

export default Durable;
