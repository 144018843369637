import config from "../../config"
import RequestService from "../RequestService"

const completeTicketWorkflowStep = (id, assignedUserId, assignedUserName, assignedUserEmail, signedByName, signatureFile, successCallback, failureCallback) => {
  let formData = new FormData()
  formData.append("assigned_user_id", assignedUserId)
  formData.append("assigned_user_email", assignedUserEmail)
  formData.append("assigned_user_name", assignedUserName)
  formData.append("signed_by_name", signedByName)
  formData.append("signature_file", signatureFile)
  RequestService.post(`${config.backendUrl}/ticket_workflow_steps/${id}/complete`, formData, successCallback, failureCallback)
}

export default completeTicketWorkflowStep;