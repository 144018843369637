import {
  Badge, Box, Link, Stack, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import AlpineDataGrid from '../../components/AlpineDataGrid';
import fetchUsers from '../../services/users/FetchUsers';

const Users = ({ authStore }) => {
  const [users, setUsers] = useState([]);

  const history = useHistory();

  if (!authStore.isAdmin) {
    history.push('/home');
  }

  const populateUsers = () => {
    fetchUsers((response) => {
      setUsers(response.data);
    });
  };

  useEffect(() => {
    populateUsers();
  }, []);

  return (
    <Box>
      <Box>
        <Typography variant="h6">Users</Typography>
      </Box>
      <AlpineDataGrid
        rows={users.map((user) => ({
          id: user.attributes.id,
          name: user.attributes.full_name,
          account_type: user.attributes.account_type,
          active: user.attributes.active,
          last_activity_at: user.attributes.last_activity_at ? format(new Date(user.attributes.last_activity_at), 'MM/dd/yyyy HH:mm:ss') : '',
          last_logged_in_at: user.attributes.last_logged_in_at ? format(new Date(user.attributes.last_logged_in_at), 'MM/dd/yyyy HH:mm:ss') : '',
          organization_name: user.attributes.organization.name,
        }))}
        columns={[
          {
            field: 'id',
            headerName: '#',
            width: 50,
            renderCell: (params) => (
              // eslint-disable-next-line
              (params.row.read_only && !authStore.canViewMoney) ? <Typography>{params.value}</Typography>
              // eslint-disable-next-line
                : <Link><Typography>{params.value}</Typography></Link>
            ),
          },
          { field: 'name', headerName: 'Name', width: 200 },
          { field: 'account_type', headerName: 'Account Type', width: 200 },
          { field: 'organization_name', headerName: 'Organization Name', width: 200 },
          { field: 'last_activity_at', headerName: 'Last Activity', width: 200 },
          { field: 'last_logged_in_at', headerName: 'Last Logged In', width: 200 },
          {
            field: 'active',
            headerName: 'Active',
            width: 100,
            renderCell: ({ value }) => (
              <Badge color={value ? 'success' : 'secondary'} badgeContent=" " />
            ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: () => (
              // eslint-disable-next-line
              <Stack direction="row">
                N/A
              </Stack>

            ),
          },
        ]}
      />
    </Box>
  );
};

export default Users;
