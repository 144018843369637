import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const MarketingCard = ({
  text, icon, title, link,
}) => (
  <Box
    sx={{
      p: 2,
      boxShadow: '0px 0px 17.9px 0px rgba(114, 114, 114, 0.08)',
      border: '1px solid #F0F0F0',
      borderRadius: '10px',
    }}
  >
    <Grid container>
      <Grid item sm={2}>
        <Box width="44px" pt={1}>
          {icon}
        </Box>
      </Grid>
      <Grid item sm={10}>
        <Box>
          <Typography fontSize="20px" fontWeight={600}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="20px" fontWeight={400}>
            {text}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default MarketingCard;
