import {
  Box, Checkbox, FormControlLabel, Typography,
} from '@mui/material';
import React from 'react';
import AlpineCard from '../../../components/AlpineCard';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import updateWorkflowStep from '../../../services/workflow_steps/UpdateWorkflowStep';

const WorkflowStep = ({ workflowStep, stepNumber }) => (
  <AlpineCard>
    <Typography variant="h6">
      {stepNumber}
      .
      {' '}
      {workflowStep.name}
    </Typography>
    <Box>
      <Typography variant="body1">{workflowStep.instructions}</Typography>
      <FormControlLabel
        control={(
          <Checkbox
            defaultChecked={workflowStep.read_only}
            onChange={(e) => updateWorkflowStep(workflowStep.id, { read_only: e.target.checked })}
          />
)}
        label="Read Only"
      />
      <FormControlLabel
        control={(
          <Checkbox
            defaultChecked={workflowStep.publicly_accessible}
            onChange={(e) => updateWorkflowStep(
              workflowStep.id, { publicly_accessible: e.target.checked },
            )}
          />
)}
        label="Publicly Accessible"
      />
      <FormControlLabel
        control={(
          <Checkbox
            defaultChecked={workflowStep.signature_required}
            onChange={(e) => updateWorkflowStep(
              workflowStep.id, { signature_required: e.target.checked },
            )}
          />
)}
        label="Signature Required"
      />
      <FormControlLabel
        control={(
          <Checkbox
            defaultChecked={workflowStep.hide_financials}
            onChange={(e) => updateWorkflowStep(
              workflowStep.id, { hide_financials: e.target.checked },
            )}
          />
)}
        label="Hide Financials"
      />
      <SimpleDataDisplay label="User Type" value={workflowStep.user_account_types.join(', ')} variant="body1" />
      <SimpleDataDisplay label="Signable By" value={workflowStep.signable_by_account_type} variant="body1" />
    </Box>
  </AlpineCard>
);

export default WorkflowStep;
