import { Box, Typography } from '@mui/material';
import React from 'react';

const Settings = () => (
  <Box>
    <Box>
      <Typography variant="h6">
        This page is currently under construction.
      </Typography>
    </Box>
    <Box>
      <Typography variant="body1">
        You will be able to update your organizations settings here.
        {' '}
        Such as projects, materials, equipment, labor types, etc.
      </Typography>
    </Box>
  </Box>
);

export default Settings;
