import {
  Box, Typography,
} from '@mui/material';
import React from 'react';

import WorklogForm from './components/WorklogForm';

const NewWorklog = ({ alertStore }) => (
  <Box>
    <Box mb={2}>
      <Typography variant="h5">New Work Log</Typography>
    </Box>
    <Box mb={2}>
      <WorklogForm alertStore={alertStore} />
    </Box>
  </Box>
);

export default NewWorklog;
