import {
  Box, Grid, Typography, IconButton,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useState, useEffect } from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchMaterials from '../../../services/materials/FetchMaterials';
import AuthStore from '../../../stores/AuthStore';
import moneyFormat from '../../../services/MoneyFormat';
import createMaterial from '../../../services/materials/CreateMaterial';

const emptyMaterial = {
  material_id: null, unit_count: 0, unitType: '', cost_per_unit: 0,
};

const calculateMaterialRow = (materialRow) => (
  Math.round(materialRow.unit_count * materialRow.cost_per_unit * 100) / 100
);

const calculateMaterialSubtotal = (materials) => (
  materials.reduce((total, material) => total + calculateMaterialRow(material), 0)
);

const MaterialSection = ({ formik, readOnly, alertStore }) => {
  const [materials, setMaterials] = useState([]);

  const authStore = new AuthStore();

  const populateMaterials = (callback) => {
    fetchMaterials((response) => {
      setMaterials(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateMaterials();
  }, []);

  const handleCreate = (material) => {
    createMaterial({ name: material }, (response) => {
      populateMaterials(() => {
        formik.setFieldValue(`ticket_materials.${formik.values.ticket_materials.length - 1}.material_id`, response.data.id);
      });
      alertStore.setAlertMessage('Material added!');
    });
  };

  const displayMaterialUnitType = (material_id) => {
    if (!material_id || materials.length === 0) return '';
    return materials.filter((material) => (
      material.attributes.id === parseInt(material_id, 10)
    ))[0]?.attributes?.unit;
  };

  const getMaterialUnitCost = (material_id) => {
    if (!material_id || materials.length === 0) return 0;
    return materials.filter((material) => (
      material.attributes.id === parseInt(material_id, 10)
    ))[0].attributes.cost_per_unit.cents / 100;
  };

  return (
    <Box id="material">
      <FieldArray
        name="ticket_materials"
        render={
          (arrayHelpers) => (
            <Grid container width="100%">
              <Grid item md={12} mb={1} width="100%">
                <Typography variant="h6">Material</Typography>
              </Grid>
              {
                formik.values.ticket_materials.map((material, index) => (
                  <>
                    <Grid item md={3} width="100%" mb={2}>
                      <MuiFormikAutocomplete
                        name={`ticket_materials.${index}.material_id`}
                        label="Material"
                        formik={formik}
                        options={materials}
                        value={formik.values.ticket_materials[index].material_id}
                        disabled={readOnly}
                        freeSolo
                        onCreate={(name) => handleCreate(name)}
                        onChange={(returnedMaterial) => {
                          if (returnedMaterial) {
                            formik.setFieldValue(
                              `ticket_materials.${index}.cost_per_unit`,
                              getMaterialUnitCost(returnedMaterial.attributes.id),
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_materials.${index}.unit_count`}
                        label="Unit"
                        type="number"
                        size="small"
                        value={formik.values.ticket_materials[index].unit_count}
                        disabled={readOnly}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <Box alignItems="center">
                        <Typography variant="body1">
                          {displayMaterialUnitType(
                            formik.values.ticket_materials[index].material_id,
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      {authStore.canViewMoney && (
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_materials.${index}.cost_per_unit`}
                        value={formik.values.ticket_materials[index].cost_per_unit}
                        label="$/unit"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                      )}
                    </Grid>
                    <Grid item sm={1} width="100%">
                      {authStore.canViewMoney && (
                      <Box>
                        <Typography variant="body1">
                          Total Cost
                        </Typography>
                        <Typography variant="body1">
                          {moneyFormat(calculateMaterialRow(material))}
                        </Typography>
                      </Box>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {!formik.values.ticket_labor_types[index]?.id && (
                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                      )}
                    </Grid>
                    <Grid item md={4} />
                    <Grid item md={12} mb={2} />
                  </>
                ))
              }
              {!readOnly && (
              <Grid item md={3} mt={1} width="100%">
                <AlpineButton
                  variant="contained"
                  fullWidth
                  startIcon={<ControlPointIcon />}
                  size="small"
                  onClick={() => arrayHelpers.push(emptyMaterial)}
                >
                  Add Material
                </AlpineButton>
              </Grid>
              )}
            </Grid>
          )
        }
      />
    </Box>
  );
};

export default MaterialSection;
export { emptyMaterial, calculateMaterialRow, calculateMaterialSubtotal };
