import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AlpineCard from '../../../components/AlpineCard';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import fetchOrganization from '../../../services/organizations/FetchOrganization';
import AuthStore from '../../../stores/AuthStore';

const AccountCard = () => {
  const [organization, setOrganization] = useState({});

  const authStore = new AuthStore();

  useEffect(() => {
    fetchOrganization(authStore.user.organization_id, (response) => {
      setOrganization(response.data.attributes);
    });
  }, []);

  return (
    <AlpineCard>
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h5">
            Account Information
          </Typography>
        </Box>
        <SimpleDataDisplay label="User name" value={authStore.user.username} />
        <SimpleDataDisplay label="Email" value={authStore.user.email} />
        <SimpleDataDisplay label="First name" value={authStore.user.first_name} />
        <SimpleDataDisplay label="Last name" value={authStore.user.last_name} />
        <SimpleDataDisplay label="Account type" value={authStore.user.username} />
        <SimpleDataDisplay label="UUID" value={authStore.user.username} />
        <SimpleDataDisplay label="Organization" value={organization.name} />
        <SimpleDataDisplay label="Organization Users" value={organization.user_count} />
      </Box>
    </AlpineCard>
  );
};

export default AccountCard;
