import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ConstructionIcon from '@mui/icons-material/Construction';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WidgetsIcon from '@mui/icons-material/Widgets';
import GroupsIcon from '@mui/icons-material/Groups';
import Logout from '@mui/icons-material/Logout';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';

const ProfileMenu = ({ authStore }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{authStore.user?.first_name[0]}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '150px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => history.push('/home')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          Home
        </MenuItem>
        <MenuItem onClick={() => history.push('/account')}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          Account
        </MenuItem>
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/workflows')}>
          <ListItemIcon>
            <AccountTreeIcon fontSize="small" />
          </ListItemIcon>
          Workflows
        </MenuItem>
        )}
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/users')}>
          <ListItemIcon>
            <GroupsIcon fontSize="small" />
          </ListItemIcon>
          Users
        </MenuItem>
        )}
        {authStore.isSuperAdmin && (
        <MenuItem onClick={() => history.push('/organizations')}>
          <ListItemIcon>
            <CorporateFareIcon fontSize="small" />
          </ListItemIcon>
          Organizations
        </MenuItem>
        )}
        <Divider />
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/materials')}>
          <ListItemIcon>
            <WidgetsIcon fontSize="small" />
          </ListItemIcon>
          Materials
        </MenuItem>
        )}
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/equipment-items')}>
          <ListItemIcon>
            <ConstructionIcon fontSize="small" />
          </ListItemIcon>
          Equipment
        </MenuItem>
        )}
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/labor-types')}>
          <ListItemIcon>
            <EngineeringIcon fontSize="small" />
          </ListItemIcon>
          Labor Types
        </MenuItem>
        )}
        <Divider />
        {authStore.isAdmin && (
        <MenuItem onClick={() => history.push('/settings')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        )}
        <MenuItem onClick={() => authStore.logout(() => { window.location = '/login'; })}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default observer(ProfileMenu);
