import React from 'react';
import {
  Box, Divider, Link, Stack, Toolbar,
} from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ProfileMenu from './components/ProfileMenu';

const Header = ({
  authStore,
}) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      borderBottom: '1px solid #8f91941c',
      width: '100%',
      background: 'white',
      zIndex: 999,
    }}
    className="hide-print"
  >
    <Toolbar disableGutters>
      <Box
        px={window.innerWidth < 768 ? '1rem' : '15rem'}
        width="100%"
      >
        {!authStore.loggedIn ? (
          <Stack direction="horizontal">
            <Box mr={6}>
              <Link href={`${authStore.loggedIn ? '/home' : '/'}`} underline="none">
                Ticket
                <strong>Worx</strong>
              </Link>
            </Box>
            {!authStore.loggedIn && (
              <Box mr={6}>
                <Link href="/login" underline="none">Login</Link>
              </Box>
            )}
            {/* {!authStore.loggedIn && (
              <Box mr={6}>
                <Link href="/register" underline="none">Create Account</Link>
              </Box>
            )} */}
          </Stack>
        )
          : (
            <Stack className="hide-mobile" direction="horizontal">
              {/* <Box mr={10}>
                  <Typography variant="h5">{customerStore.customer?.organization?.name}</Typography>
                </Box>
                <Box mr={6}>
                  <Link href="/events" underline="none">
                    <Typography variant="h5">Find events</Typography>
                  </Link>
                </Box>
                <Box>
                  <Link href="/my-events" underline="none">
                    <Typography variant="h5">My events</Typography>
                  </Link>
                </Box> */}
            </Stack>
          )}
      </Box>
      {authStore.loggedIn && (
        <Box style={{ position: 'fixed', right: window.innerWidth < 768 ? '30px' : '150px' }}>
          <ProfileMenu authStore={authStore} />
        </Box>
      )}
    </Toolbar>
    <Divider />
  </Box>
);

Header.propTypes = {
  authStore: PropTypes.any,
};

export default observer(Header);
