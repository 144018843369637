import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

const optionExists = (newOption, options) => {
  if (newOption.id) return true;
  return options.filter((option) => (
    option.attributes.name.toUpperCase() === newOption.toUpperCase())).length > 0;
};

const getOption = (foundOption, options) => {
  if (foundOption.id) return foundOption;
  return options.filter((option) => (
    option.attributes.name.toUpperCase() === foundOption.toUpperCase()
  ))[0];
};

const MuiFormikAutocomplete = ({
  name, options, label, formik, onChange, value, disabled, onCreate, freeSolo = false,
}) => {
  if (options.length === 0) return <Box />;
  const getOptionLabel = (option) => {
    if (option.attributes) return option.attributes.name;
    if (!value) return '';
    const selectedOption = options.filter((opt) => (
      parseInt(opt.attributes.id, 10) === parseInt(value, 10)
    ))[0];
    if (selectedOption) return selectedOption.attributes.name;
    return '';
  };

  return (
    <Autocomplete
      options={options}
      autoSelect
      isOptionEqualToValue={(option, val) => option.attributes.id === val}
      getOptionLabel={getOptionLabel}
      value={value}
      disabled={disabled}
      freeSolo={freeSolo}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
        />
      )}
      onChange={(_event, option) => {
        if (option && option.id) {
          formik.setFieldValue(name, option ? option.id : null);
          if (onChange) onChange(option);
        } else if (onCreate) {
          if (!optionExists(option, options)) {
            onCreate(option);
          } else {
            const foundOption = getOption(option, options);
            formik.setFieldValue(name, foundOption.id);
            if (onChange) onChange(foundOption);
          }
        }
      }}
    />
  );
};

export default MuiFormikAutocomplete;
