import {
  Box, Grid, Skeleton, Stack, Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import createTicketNote from '../../../services/ticket_notes/CreateTicketNote';
import fetchTicketNotes from '../../../services/ticket_notes/FetchTicketNotes';

const TicketNotes = ({ ticket }) => {
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(true);

  const populateNotes = () => {
    fetchTicketNotes(ticket.id, (response) => {
      setNotes(response.data);
      setNotesLoading(false);
    });
  };

  useEffect(() => {
    populateNotes();
  }, []);

  const handleSubmit = (values) => {
    createTicketNote(ticket.id, values, populateNotes);
  };

  if (notesLoading) return <Skeleton variant="rectangular" width="100%" height={50} />;

  return (
    <Box>
      <Box>
        <Typography variant="h6">Notes</Typography>
      </Box>
      <Stack direction="column" spacing={1}>
        {
          notes.map((note) => (
            <Grid container>
              <Grid item md={8} textAlign="left">
                &quot;
                {note.attributes.details}
                &quot;
              </Grid>
              <Grid item md={4} textAlign="right">
                <Typography variant="caption">
                  {note.attributes.created_by_name && (
                  <i>
                    -
                    {note.attributes.created_by_name}
                    {' '}
                    on
                    {' '}
                  </i>
                  )}
                  <i>
                    {format(new Date(note.attributes.created_at), "MM/dd 'at' HH:mm")}
                  </i>
                </Typography>
              </Grid>
            </Grid>
          ))
        }
      </Stack>
      <Box mt={1}>
        <Formik
          initialValues={{
            details: '',
          }}
          onSubmit={(values, { resetForm }) => {
            if (values.details) handleSubmit(values);
            resetForm({});
          }}
        >
          {
            (formik) => (
              <Stack direction="column" spacing={1}>
                <MuiFormikTextField
                  label="Note"
                  size="small"
                  formik={formik}
                  name="details"
                />
                <AlpineButton onClick={formik.handleSubmit}>Save Note</AlpineButton>
              </Stack>
            )
          }
        </Formik>
      </Box>
    </Box>

  );
};

export default TicketNotes;
