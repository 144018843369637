import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ message }) => (
  <Box textAlign="center" pt={5}>
    <Grid item md={12}>
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    </Grid>
    <Grid item md={12}>
      <Box textAlign="center">
        <em>{message}</em>
      </Box>
    </Grid>
  </Box>
);

Loader.propTypes = {
  message: PropTypes.string,
};

export default Loader;
