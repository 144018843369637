import {
  Autocomplete, Box, Checkbox, Dialog, Divider, FormControlLabel, Stack, TextField, Typography,
} from '@mui/material';

import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import completeTicketWorkflowStep from '../../../services/ticketWorkflowSteps/CompleteTicketWorkflowStep';
import AlertStore from '../../../stores/AlertStore';
import { displayUserName } from './AssignedTicket';
import SignHere from './SignHere';

const ApproveTicketDialog = ({
  open, handleClose, ticket, showAssignUser,
}) => {
  const [assignedUserId, setAssignedUserId] = useState(null);
  const [assignedUserName, setAssignedUserName] = useState(null);
  const [assignedUserEmail, setAssignedUserEmail] = useState(null);
  const [agree, setAgree] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signedByName, setSignedByName] = useState('');
  const [signatureFile, setSignatureFile] = useState(false);

  const sigRef = useRef(null);

  const history = useHistory();

  const alertStore = new AlertStore();

  const convertDataUriToFile = (dataUri) => {
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataUri.split(',')[1]);
    else byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    setSignatureFile(new Blob([ia], { type: mimeString }));
  };

  const handleComplete = () => {
    setSubmitting(true);
    // TODO: convert this to a form data call and pass the signature file
    completeTicketWorkflowStep(
      ticket.current_step_id, assignedUserId, assignedUserName, assignedUserEmail,
      signedByName, signatureFile,
      () => {
        alertStore.setAlertMessage('Successfully submitted ticket!');
        history.push('/tickets');
      },
    );
  };

  return (
    <Dialog
      open={open}
    >
      <Box p={2}>
        <Typography variant="h6">Complete step</Typography>
        <Typography variant="caption">
          Assigned to:
          {' '}
          {ticket.assigned_to_name}
          {' '}
          (
          {ticket.assigned_to_email}
          )
        </Typography>
        {showAssignUser && (
          <Box mb={4}>
            <Typography variant="caption">Assign this ticket</Typography>
            {ticket.assignable_users.length > 0 ? (
              <Box>
                <Autocomplete
                  options={ticket.assignable_users}
                  value={assignedUserId}
                  isOptionEqualToValue={(option, selected) => option.id === selected}
                  getOptionLabel={(option) => (
                    option.first_name
                      ? displayUserName(option)
                      : displayUserName(ticket.assignable_users.filter((opt) => (
                        opt.id === assignedUserId
                      ))[0])
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assignee"
                      variant="outlined"
                    />
                  )}
                  onChange={(_event, option) => {
                    setAssignedUserId(option ? option.id : null);
                  }}
                />
              </Box>
            )
              : (
                <Box mb={4}>
                  <Box>
                    <Box mb={2}>
                      <TextField
                        name="name"
                        label="Name"
                        fullWidth
                        size="small"
                        onChange={(e) => setAssignedUserName(e.target.value)}
                        value={assignedUserName}
                      />
                    </Box>
                    <Box>
                      <TextField
                        name="email"
                        label="Email"
                        fullWidth
                        size="small"
                        onChange={(e) => setAssignedUserEmail(e.target.value)}
                        value={assignedUserEmail}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        )}
        <Box my={2}>
          <Divider />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            label="This information is accurate to the best of my knowledge and I am authorized to approve this ticket"
            control={(
              <Checkbox
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
            )}
          />
        </Box>
        {ticket.signature_required && (
        <Box mb={2}>
          <SignHere
            onEnd={() => convertDataUriToFile(sigRef.current.toDataURL())}
            sigRef={sigRef}
          />
          <TextField
            value={signedByName}
            label="Enter your name"
            size="small"
            onChange={(e) => setSignedByName(e.target.value)}
            fullWidth
          />
        </Box>
        )}
        <Box>
          <Stack direction="row" spacing={2}>
            <AlpineButton
              disabled={
                (ticket.assignable_users.length > 0 && !assignedUserId && showAssignUser)
                || !agree || (signedByName.length === 0 && ticket.signature_required)
                || sigRef.current?.isEmpty()
                || submitting
              }
              variant="contained"
              onClick={handleComplete}
            >
              Submit
            </AlpineButton>
            <AlpineButton onClick={handleClose}>
              Cancel
            </AlpineButton>
          </Stack>

        </Box>
      </Box>
    </Dialog>
  );
};

export default ApproveTicketDialog;
