import config from "../../config"
import RequestService from "../RequestService"

const createTicketImage = (ticketId, stagedPhoto, successCallback, failureCallback) => {
  let formData = new FormData()
  formData.append("image", stagedPhoto)
  formData.append("ticket_id", ticketId)

  RequestService.post(`${config.backendUrl}/ticket_images`, formData, successCallback, failureCallback)
}

export default createTicketImage;