import React from 'react';
import Default from '../viewTemplates/Default';
import Durable from '../viewTemplates/Durable';

const PrintableTicketView = ({ ticket }) => {
  switch (ticket.ticket_template) {
    case 'default':
      return <Default ticket={ticket} />;
    case 'durable':
      return <Durable ticket={ticket} />;
  }
};

export default PrintableTicketView;
