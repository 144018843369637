import {
  Alert, Autocomplete, Box, Stack, TextField,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import completeTicketWorkflowStep from '../../../services/ticketWorkflowSteps/CompleteTicketWorkflowStep';

const displayUserName = (user) => `${user?.first_name} ${user?.last_name}`;

const AssignedTicket = ({ ticketWorkflowStep }) => {
  const [assignedUserId, setAssignedUserId] = useState(null);
  const [completedAt, setCompletedAt] = useState(ticketWorkflowStep.completed_at
    && new Date(ticketWorkflowStep.completed_at));

  const history = useHistory();

  const handleComplete = () => {
    completeTicketWorkflowStep(ticketWorkflowStep.id, assignedUserId);
    setCompletedAt(new Date());
  };

  return (
    <Box>
      <SimpleDataDisplay label="ID" value={ticketWorkflowStep.ticket.id} />
      <SimpleDataDisplay label="Step" value={ticketWorkflowStep.workflow_step.name} />
      <SimpleDataDisplay label="Project Name" value={ticketWorkflowStep.project_name} />
      <SimpleDataDisplay label="Instructions" value={ticketWorkflowStep.workflow_step.instructions} />
      <Box mb={2}>
        {ticketWorkflowStep.completed_at
          ? <SimpleDataDisplay label="Assigned to" value={displayUserName(ticketWorkflowStep.ticket_currently_assigned_to)} />
          : (
            <Stack>
              <Autocomplete
                options={ticketWorkflowStep.assignable_users}
                value={assignedUserId}
                isOptionEqualToValue={(option, selected) => option.id === selected}
                getOptionLabel={(option) => (
                  option.first_name
                    ? displayUserName(option)
                    : displayUserName(ticketWorkflowStep.assignable_users.filter((opt) => (
                      opt.id === assignedUserId
                    ))[0])
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assignee"
                    variant="outlined"
                  />
                )}
                onChange={(_event, option) => {
                  setAssignedUserId(option ? option.id : null);
                }}
              />
            </Stack>
          )}
      </Box>
      <Box>
        {completedAt
          ? (
            <Alert severity="success">
              Completed on
              {' '}
              {format(completedAt, "MM/dd/yyyy 'at' HH:mm")}
            </Alert>
          )
          : (
            <Stack direction="row" spacing={3}>
              <AlpineButton variant="contained" onClick={handleComplete} disabled={!assignedUserId}>
                Mark Complete
              </AlpineButton>
              <AlpineButton onClick={() => history.push(`/tickets/${ticketWorkflowStep.ticket.id}/edit`)}>
                View Ticket
              </AlpineButton>
              <AlpineButton onClick={() => history.push(`/workflows/${ticketWorkflowStep.workflow_step.workflow_id}`)}>
                View Workflow
              </AlpineButton>
            </Stack>
          )}
      </Box>
    </Box>
  );
};

export default AssignedTicket;
export { displayUserName };
