import { Box, Modal } from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '5px',
};

const modalStyle = {
  position: 'absolute',
  overflow: 'scroll',
  height: '100%',
  display: 'block',
};

const WushModal = ({
  open, onClose, width = 400, children,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    style={modalStyle}
  >
    <Box sx={style} width={width}>
      {children}
    </Box>
  </Modal>
);

export default WushModal;
