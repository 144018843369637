import { Box, Divider, Grid } from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchProjects from '../../../services/projects/FetchProjects';
import WorklogEquipmentSection, { emptyEquipment } from './WorklogEquipmentSection';
import WorklogLaborSection, { emptyLabor } from './WorklogLaborSection';
import WorklogMaterialSection, { emptyMaterial } from './WorklogMaterialSection';

const WorklogForm = ({ initialValues, alertStore, readOnly = false }) => {
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);

  const history = useHistory();

  const populateProjects = (callBack) => {
    fetchProjects((response) => {
      setProjects(response.data);
      setProjectsLoading(false);
      if (callBack) callBack();
    });
  };

  useEffect(() => {
    populateProjects();
  }, []);

  return (
    <Formik
      initialValues={initialValues || {
        work_completed_on: format(new Date(), 'yyyy-MM-dd'),
        description: '',
        project_id: null,
        worklog_labor_types: [emptyLabor],
        worklog_equipment_items: [emptyEquipment, emptyEquipment, emptyEquipment],
        worklog_materials: [emptyMaterial],
        comments: '',
      }}
    >
      {
        (formik) => (
          <Box>
            <Grid container>
              <Grid item md={4} width="100%" mb={2}>
                <Box pr={2}>
                  <MuiFormikTextField
                    formik={formik}
                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                    label="Date of Work"
                    name="work_completed_on"
                    value={formik.values.work_completed_on}
                    fullWidth
                    type="date"
                    size="small"
                    disabled={readOnly}
                  />
                </Box>
              </Grid>
              <Grid item md={4} width="100%" mb={2}>
                <Box mb={1}>
                  <MuiFormikAutocomplete
                    name="project_id"
                    label="Project"
                    formik={formik}
                    value={formik.values.project_id}
                    options={projects}
                    disabled={readOnly}
                  />
                </Box>
              </Grid>
              <Grid item md={4} />
              <Grid item md={12} width="100%" mb={2}>
                <MuiFormikTextField
                  formik={formik}
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  fullWidth
                  size="small"
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <WorklogLaborSection formik={formik} alertStore={alertStore} />
            <Box>
              <MuiFormikTextField
                formik={formik}
                label="Safety Discussion"
                name="discussion"
                value={formik.values.discussion}
                fullWidth
                size="small"
                disabled={readOnly}
              />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <WorklogEquipmentSection formik={formik} alertStore={alertStore} />
            <Box my={2}>
              <Divider />
            </Box>
            <WorklogMaterialSection formik={formik} alertStore={alertStore} />
            <Box my={2}>
              <Divider />
            </Box>
            <Box mb={2}>
              <MuiFormikTextField
                formik={formik}
                label="Comments"
                name="comments"
                value={formik.values.comments}
                fullWidth
                size="small"
                disabled={readOnly}
              />
            </Box>
            <Box mb={2}>
              <MuiFormikTextField
                formik={formik}
                label="Employee Absence"
                name="additional_notes"
                value={formik.values.additional_notes}
                fullWidth
                size="small"
                disabled={readOnly}
              />
            </Box>
          </Box>
        )
      }
    </Formik>
  );
};

export default WorklogForm;
