import {
  Box, CircularProgress,
  Grid, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

import { Redirect, useHistory, useRouteMatch } from 'react-router';
import AlpineButton from '../../components/AlpineButton';
import AlpineCard from '../../components/AlpineCard';
import fetchTicket from '../../services/tickets/FetchTicket';
import updateTicket from '../../services/tickets/UpdateTicket';
import ApproveTicketDialog from './components/ApproveTicketDialog';
import TicketForm from './components/TicketForm';
import TicketNotes from './components/TicketNotes';
import TicketTimeline from './components/TicketTimeline';

const EditTicket = ({ alertStore }) => {
  const [ticket, setTicket] = useState({});
  const [ticketLoading, setTicketLoading] = useState(true);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const match = useRouteMatch();
  const { id } = match.params;

  const history = useHistory();

  useEffect(() => {
    fetchTicket(id, (response) => {
      setTicket(response.data);
      setTicketLoading(false);
    });
  }, []);

  if (ticketLoading) return <CircularProgress />;

  if (ticket.attributes.read_only) return <Redirect to={`/tickets/${ticket.id}`} />;

  const handleSubmit = (values, hideAlert = false, callback = null) => {
    const params = {
      ...values,
    };
    updateTicket(id, params, () => {
      if (!hideAlert) alertStore.setAlertMessage('Ticket saved! Please remember to complete this ticket when you are ready to assign it to the next user.');
      if (callback) callback();
    });
  };

  const mappedTicket = {
    ...ticket.attributes,
    id: ticket.id,
    ticket_materials: ticket.attributes.ticket_materials.map((ticket_material) => ({
      cost_per_unit: ticket_material.cost_per_unit_cents / 100,
      ...ticket_material,
    })),
    // eslint-disable-next-line
    ticket_equipment_items: ticket.attributes.ticket_equipment_items.map((ticket_equipment_item) => ({
      hourly_rate: ticket_equipment_item.hourly_rate_cents / 100,
      ...ticket_equipment_item,
    })),
    ticket_labor_types: ticket.attributes.ticket_labor_types.map((ticket_labor_type) => ({
      hourly_rate: ticket_labor_type.hourly_rate_cents / 100,
      ...ticket_labor_type,
    })),
  };

  return (
    <Box>
      <Box mb={2} className="hide-print">
        <AlpineButton onClick={() => history.push('/home')}>Back to Tickets</AlpineButton>
      </Box>
      <Box mb={2}>
        <Typography variant="h6">View Ticket</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">
          Ticket created by
          {' '}
          {ticket.attributes.created_by_name || 'N/A'}
        </Typography>
      </Box>
      <Grid container>
        <Grid item md={8}>
          <TicketForm
            initialValues={mappedTicket}
            onSubmit={handleSubmit}
            readOnly={ticket.attributes.read_only}
          />
        </Grid>
        <Grid item md={4}>
          <Box pl={5} mb={2}>
            <TicketTimeline ticket={ticket} />
            <AlpineCard>
              <Box p={2}>
                <AlpineButton variant="contained" fullWidth onClick={() => setShowCompleteModal(true)}>
                  Complete Ticket
                </AlpineButton>
              </Box>
            </AlpineCard>
          </Box>
          <Box pl={5}>
            <TicketNotes ticket={ticket} />
          </Box>
        </Grid>
      </Grid>

      <ApproveTicketDialog
        open={showCompleteModal}
        handleClose={() => setShowCompleteModal(false)}
        ticket={ticket.attributes}
        showAssignUser
      />

      <Box pt={2}>
        <AlpineButton onClick={() => history.goBack()}>
          Back
        </AlpineButton>
      </Box>
    </Box>
  );
};

export default EditTicket;
