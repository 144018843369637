import {
  Box, Grid, IconButton, InputAdornment, Table, TableBody, Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import createLaborType from '../../../services/labor_types/CreateLaborType';
import fetchLaborTypes from '../../../services/labor_types/FetchLaborTypes';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';

const emptyLabor = {
  labor_type_id: null, worker_count: 0, hours: 0, hourly_rate: 0,
};

const calculateTotalLaborHours = (labors) => labors.reduce((total, labor) => total + parseInt(labor.hours), 0);

const WorklogLaborSection = ({ formik, alertStore, readOnly = false }) => {
  const [laborTypes, setLaborTypes] = useState([]);

  const populateLaborTypes = (callback) => {
    fetchLaborTypes((response) => {
      setLaborTypes(response.data);
      if (callback) callback();
    });
  };

  const handleCreate = (laborType) => {
    createLaborType({ name: laborType }, (response) => {
      populateLaborTypes(() => {
        formik.setFieldValue(`ticket_labor_types.${formik.values.ticket_labor_types.length - 1}.labor_type_id`, response.data.id);
      });
      alertStore.setAlertMessage('Labor type added!');
    });
  };

  useEffect(() => {
    populateLaborTypes();
  }, []);

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography variant="h6">
          Labor
        </Typography>
      </Box>
      <Box>
        <FieldArray
          name="worklog_labor_types"
          render={
            (arrayHelpers) => (
              <Grid container width="100%">
                {
                  formik.values.worklog_labor_types.map((labor, index) => (
                    <>
                      <Grid item md={3} width="100%" mb={2}>
                        <MuiFormikAutocomplete
                          name={`worklog_labor_types.${index}.labor_type_id`}
                          label="Labor Type"
                          formik={formik}
                          options={laborTypes}
                          freeSolo
                          onCreate={(name) => handleCreate(name)}
                          value={labor.labor_type_id}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid paddingX={1} item md={3} width="100%" mb={2}>
                        <MuiFormikTextField
                          name={`worklog_labor_types.${index}.hours`}
                          label="Hours"
                          type="number"
                          formik={formik}
                          options={laborTypes}
                          value={labor.hours}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item md={2} width="100%">
                        {!formik.values.worklog_labor_types[index]?.id && (
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                        )}
                      </Grid>
                      <Grid item md={4} />
                    </>
                  ))
                }
                {!readOnly && (
                  <Grid item md={3} mt={1} width="100%" mb={2}>
                    <AlpineButton
                      variant="contained"
                      fullWidth
                      startIcon={<ControlPointIcon />}
                      size="small"
                      onClick={() => arrayHelpers.push(emptyLabor)}
                    >
                      Add Labor
                    </AlpineButton>
                  </Grid>
                )}
              </Grid>
            )
          }
        />
        <Grid container my={4}>
          <Grid item md={3} mb={2} width="100%">
            <SimpleDataDisplay label="Total Hours" value={calculateTotalLaborHours(formik.values.worklog_labor_types)} />
          </Grid>
          <Grid item md={3} width="100%">
            <MuiFormikTextField
              name="percent_complete"
              label="Percentage of Work Completed"
              type="number"
              formik={formik}
              options={laborTypes}
              value={formik.values.percent_complete}
              disabled={readOnly}
              InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WorklogLaborSection;
export { emptyLabor };
