import { Box, Typography } from '@mui/material';
import React from 'react';

const SimpleDataDisplay = ({ label, value, variant = 'h6' }) => (
  <Box>
    <Typography variant={variant}>
      <strong>
        {label}
        :
        {' '}
      </strong>
      {' '}
      {value}
    </Typography>
  </Box>
);

export default SimpleDataDisplay;
