import {
  Box, Dialog, Stack, Typography,
} from '@mui/material';
import React from 'react';
import AlpineButton from '../../../components/AlpineButton';
import deleteTicket from '../../../services/tickets/DeleteTicket';

const DeleteTicketDialog = ({
  open, handleClose, ticket, onSuccess,
}) => {
  const handleDelete = () => {
    deleteTicket(ticket.id, onSuccess);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Box p={4}>
        <Box mb={2}>
          <Typography variant="h6">
            Are you sure you want to delete?
          </Typography>
        </Box>
        {!ticket.deletable && (
        <Box mb={2}>
          <Typography>
            Warning! This ticket has been submitted
            {' '}
            but since you are an admin you can still delete this ticket.
          </Typography>
        </Box>
        )}
        <Box>
          <Stack direction="row" spacing={2}>
            <AlpineButton onClick={handleDelete} variant="contained" color="error">Delete</AlpineButton>
            <AlpineButton onClick={handleClose}>Cancel</AlpineButton>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteTicketDialog;
