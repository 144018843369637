import {
  Box, Typography,
} from '@mui/material';
import React from 'react';

import { useHistory } from 'react-router';
import AlpineButton from '../../components/AlpineButton';
import createTicket from '../../services/tickets/CreateTicket';
import TicketForm from './components/TicketForm';

const NewTicket = ({ alertStore }) => {
  const history = useHistory();

  const handleSubmit = (values) => {
    const params = {
      ...values,
    };
    createTicket(params, (response) => {
      alertStore.setAlertMessage('Ticket saved! Please review this ticket, add any images or attachments, and then complete the ticket and assign it to the next user.');
      history.push(`/tickets/${response.id}/edit`);
    });
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">New Ticket</Typography>
      </Box>
      <Box mb={2}>
        <TicketForm
          onSubmit={handleSubmit}
          alertStore={alertStore}
        />
        <Box mt={2}>
          <AlpineButton onClick={() => history.goBack()}>
            Back
          </AlpineButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NewTicket;
