import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import fetchLaborTypes from '../../services/labor_types/FetchLaborTypes';
import updateLaborType from '../../services/labor_types/UpdateLaborType';
import AlpineButton from '../../components/AlpineButton';
import createLaborType from '../../services/labor_types/CreateLaborType';
import deleteLaborType from '../../services/labor_types/DeleteLaborType';

const LaborTypes = () => {
  const [laborTypes, setLaborTypes] = useState([]);

  const populateLaborTypes = (callback) => {
    fetchLaborTypes((response) => {
      setLaborTypes(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateLaborTypes();
  }, []);

  const handleUpdate = (values) => {
    const updateParams = {};
    updateParams[values.field] = values.value;
    updateLaborType(values.id, updateParams, populateLaborTypes);
  };

  const handleCreate = () => {
    createLaborType({
      name: 'new item',
    }, (response) => {
      setLaborTypes([response.data].concat(laborTypes));
    });
  };

  const handleDelete = (id) => {
    deleteLaborType(id, populateLaborTypes);
  };

  if (!laborTypes) return <CircularProgress />;

  const laborTypeRows = laborTypes.map((laborType) => ({
    id: laborType.id,
    name: laborType.attributes.name,
    description: laborType.attributes.description,
    hourly_rate: laborType.attributes.hourly_rate.cents / 100,
  }));

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      editable: true,
    },
    {
      field: 'hourly_rate',
      headerName: 'Rate',
      width: 150,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <AlpineButton
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.id)}
        >
          Delete
        </AlpineButton>
      ),
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">
          Labor Types
        </Typography>
      </Box>
      <Box mb={2}>
        <AlpineButton onClick={handleCreate} variant="contained" startIcon={<ControlPointIcon />}>
          Add Labor Type
        </AlpineButton>
      </Box>
      <Box height="500px">
        <DataGrid
          rows={laborTypeRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={handleUpdate}
        />
      </Box>

    </Box>
  );
};

export default LaborTypes;
