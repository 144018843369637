import React from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';
import { observer } from 'mobx-react';
import Routes from './Routes';
import './App.css';
import BaseStore from './stores/BaseStore';
import AuthStore from './stores/AuthStore';
import HealthStore from './stores/HealthStore';
import Header from './containers/header/Header';
import AlertStore from './stores/AlertStore';
import Footer from './containers/footer/Footer';
import WushAlert from './components/WushAlert';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#0c6b74',
        main: '#225FAE',
        dark: '#0c6b74',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#F8BC24',
        dark: '#ba000d',
        contrastText: '#fff',
      },
      accent: {
        main: '#1E7CD4',
      },
      white: {
        main: '#fff',
        contrastText: '#225FAE',
      },
      background: {
        paper: '#ffffff',
      },
    },
    colors: {
      accent: {
        color: '#1E7CD4',
      },
    },
    typography: {
      fontFamily: 'Lato, Helvetica, sans-serif',
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontSize: '2rem',
        color: 'black',
      },
      accent: {
        color: '#1E7CD4',
      },
      highlight: {
        color: '#F8BC24',
      },
    },
  });

  const childProps = {
    user: 'Nik',
    baseStore: new BaseStore(),
    authStore: new AuthStore(),
    healthStore: new HealthStore(),
    alertStore: new AlertStore(),
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header {...childProps} />
        <Container component="main" maxWidth={false} padding={0} disableGutters>
          <WushAlert alertStore={childProps.alertStore} />
          <Box mb={5}>
            <Routes childProps={childProps} />
          </Box>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default withRouter(observer(App));
