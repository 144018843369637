import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Box, CircularProgress, Divider, Grid, Stack, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchWorkflows from '../../../services/workflows/FetchWorkflows';
import AuthStore from '../../../stores/AuthStore';
import MaterialSection, { calculateMaterialRow, emptyMaterial } from './MaterialSection';
import LaborSection, { calculateLaborRow, emptyLabor } from './LaborSection';
import EquipmentSection, { calculateEquipmentRow, emptyEquipment } from './EquipmentSection';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import fetchProjects from '../../../services/projects/FetchProjects';
import AddProjectDialog from './AddProjectDialog';
import ImageAttachmentForm from './ImageAttachmentForm';
import moneyFormat from '../../../services/MoneyFormat';
import ApproveTicketDialog from './ApproveTicketDialog';

const calculateSubtotal = ({ ticket_labor_types, ticket_materials, ticket_equipment_items }) => {
  let subTotal = 0;
  // eslint-disable-next-line
  ticket_labor_types.forEach((laborRow) => subTotal += calculateLaborRow(laborRow));
  // eslint-disable-next-line
  ticket_materials.forEach((materialRow) => subTotal += calculateMaterialRow(materialRow));
  // eslint-disable-next-line
  ticket_equipment_items.forEach((equipmentRow) => subTotal += calculateEquipmentRow(equipmentRow));
  return Math.round(subTotal * 100) / 100;
};

const calculateMarkup = ({
  markup_percentage, ticket_labor_types, ticket_materials, ticket_equipment_items,
}) => {
  const markup = (calculateSubtotal({
    ticket_labor_types, ticket_materials, ticket_equipment_items,
  }) * markup_percentage) / 100;
  return Math.round(markup * 100) / 100;
};

const TicketForm = ({
  onSubmit, initialValues = null, readOnly, alertStore,
}) => {
  const [workflows, setWorkflows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [workflowsLoading, setWorkflowsLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [addProjectOpen, setAddProjectOpen] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const authStore = new AuthStore();

  const history = useHistory();

  const populateProjects = (callBack) => {
    fetchProjects((response) => {
      setProjects(response.data);
      setProjectsLoading(false);
      if (callBack) callBack();
    });
  };

  const saveAndViewPdf = (values) => {
    onSubmit(values, true, () => history.push(`/tickets/${values.id}`));
  };

  useEffect(() => {
    populateProjects();
    fetchWorkflows((response) => {
      setWorkflows(response.data);
      setWorkflowsLoading(false);
    });
  }, []);

  if (workflowsLoading || projectsLoading) return <CircularProgress />;

  const creatableWorkflows = workflows;
  // .filter((workflow) => (
  //   workflow.attributes.creatable_by_user_types.includes(authStore.accountType)
  // ));

  return (
    <Formik
      initialValues={initialValues || {
        work_completed_on: format(new Date(), 'yyyy-MM-dd'),
        description: '',
        workflow_id: creatableWorkflows[0].id,
        project_id: null,
        markup_percentage: 10,
        ticket_labor_types: [
          emptyLabor,
        ],
        ticket_materials: [
          emptyMaterial,
        ],
        ticket_equipment_items: [
          emptyEquipment,
        ],
      }}
      // eslint-disable-next-line
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {
        (formik) => (
          <Box>
            <Box id="dateAndDescription" mb={2}>
              <Grid container width="100%">
                <Grid item md={12} width="100%">
                  <Box mb={2}>
                    <AlpineButton
                      variant="outlined"
                      startIcon={<PictureAsPdfIcon />}
                      onClick={() => saveAndViewPdf(formik.values)}
                      fullWidth
                    >
                      View PDF
                    </AlpineButton>
                  </Box>
                </Grid>
                <Grid item md={12} width="100%">
                  <Box mb={1}>
                    <MuiFormikAutocomplete
                      name="project_id"
                      label="Project"
                      formik={formik}
                      value={formik.values.project_id}
                      options={projects}
                      disabled={readOnly}
                    />
                  </Box>
                  <Box mb={3}>
                    <AlpineButton
                      variant="contained"
                      onClick={() => setAddProjectOpen(true)}
                      startIcon={<ControlPointIcon />}
                      size="small"
                    >
                      Add Project
                    </AlpineButton>
                  </Box>
                </Grid>
                <Grid item md={4} width="100%" mb={2}>
                  <Box pr={2}>
                    <MuiFormikTextField
                      formik={formik}
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      label="Date of Work"
                      name="work_completed_on"
                      value={formik.values.work_completed_on}
                      fullWidth
                      type="date"
                      size="small"
                      disabled={readOnly}
                    />
                  </Box>
                </Grid>
                <Grid item md={8} width="100%">
                  <MuiFormikTextField
                    formik={formik}
                    name="description"
                    value={formik.values.description}
                    label="Description"
                    type="text"
                    fullWidth
                    size="small"
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            </Box>
            <LaborSection formik={formik} readOnly={readOnly} alertStore={alertStore} />
            <Box my={2}>
              <Divider />
            </Box>
            <MaterialSection formik={formik} readOnly={readOnly} alertStore={alertStore} />
            <Box my={2}>
              <Divider />
            </Box>
            <EquipmentSection formik={formik} readOnly={readOnly} alertStore={alertStore} />
            <Box marginY={2}>
              <Divider />
            </Box>
            {authStore.canViewMoney
            && (
            <>
              <Box>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h6">Subtotal:</Typography>
                  <Typography variant="h6">
                    {moneyFormat(calculateSubtotal(formik.values))}
                  </Typography>
                </Stack>
              </Box>
              <Box marginY={2}>
                <Divider />
              </Box>
              <Box>
                <Stack direction="row" spacing={4} alignItems="center">
                  <Typography variant="h6">Markup (Overhead & Profit):</Typography>
                  <MuiFormikTextField
                    formik={formik}
                    name="markup_percentage"
                    label="% Markup"
                    size="small"
                    value={formik.values.markup_percentage}
                    disabled={readOnly}
                    style={{ width: '80px' }}
                  />
                  <Typography variant="body1">
                    {moneyFormat(calculateMarkup(formik.values))}
                  </Typography>
                </Stack>
              </Box>
              <Box marginY={2}>
                <Divider />
              </Box>
              <Box>
                <Stack direction="row" spacing={4} alignItems="center">
                  <Typography variant="h6">Grand total:</Typography>
                  <Typography variant="h6">
                    {
                      moneyFormat(calculateSubtotal(formik.values)
                      + calculateMarkup(formik.values))
                    }
                  </Typography>
                </Stack>
              </Box>
              <Box marginY={2}>
                <Divider />
              </Box>
            </>
            )}
            <Box>
              <Box mb={1}>
                <Typography variant="h6">Assign ticket to workflow (Temporary for test only)</Typography>
              </Box>
              <MuiFormikAutocomplete
                name="workflow_id"
                label="Workflow"
                formik={formik}
                value={formik.values.workflow_id}
                options={creatableWorkflows}
              />
            </Box>
            <Box marginY={2}>
              <Divider />
            </Box>
            {initialValues?.id && (
            <Box border="1px solid grey" p={2} borderRadius="5px">
              <ImageAttachmentForm ticket={initialValues} />
            </Box>
)}
            <Box marginY={2}>
              <Divider />
            </Box>
            <Box mb={2}>
              <AlpineButton
                onClick={() => formik.handleSubmit(formik.values, false)}
                disabled={!formik.dirty}
                variant="contained"
                fullWidth
              >
                Save Ticket
              </AlpineButton>
            </Box>
            {formik.values.id && window.innerWidth > 768 && (
              <Box my={2}>
                <AlpineButton
                  variant="contained"
                  onClick={() => {
                    onSubmit(formik.values, true);
                    setShowCompleteModal(true);
                  }}
                >
                  Complete Ticket
                </AlpineButton>
              </Box>
            )}
            <AddProjectDialog
              open={addProjectOpen}
              onClose={() => setAddProjectOpen(false)}
              onSuccess={(response) => {
                populateProjects(() => formik.setFieldValue('project_id', response.data.id));
                alertStore.setAlertMessage('Project added!');
              }}
            />
            {formik.values.id && (
            <ApproveTicketDialog
              open={showCompleteModal}
              handleClose={() => setShowCompleteModal(false)}
              ticket={formik.values}
              showAssignUser
            />
            )}
          </Box>
        )
      }
    </Formik>
  );
};

export default TicketForm;
export { calculateSubtotal, calculateMarkup };
