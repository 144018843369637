import {
  Box, Button, Divider, Link, TextField, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { Redirect, useHistory } from 'react-router';
import AlpineCard from '../../components/AlpineCard';
import Loader from '../../components/loader/Loader';

const RegisterForm = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Email is required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Password is required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match!")
    .required('Repeat password is required'),
});

const errorMessage = (
  <Typography variant="body1">
    Email already in use.
    <Link href="/login">Login?</Link>
  </Typography>
);

const useStyles = makeStyles(() => ({
  emailContainer: {
    width: '300px',
  },
  passwordContainer: {
    width: '300px',
  },
  buttonContainer: {
    verticalAlign: 'middle',
  },
  errorContainer: {
    marginLeft: '33%',
    marginTop: '10px',
  },
}));

const Register = ({ baseStore, authStore }) => {
  const classes = useStyles();
  const history = useHistory();
  baseStore.getHealthCheck();
  if (authStore.loggedIn) return <Redirect to="/home" />;
  if (baseStore.healthLoading) return <Loader />;
  if (authStore.loginLoading) return <Loader />;

  return (
    <Box pt={10} textAlign="center" width="100%">
      <Box maxWidth="md" mr="auto" ml="auto">
        <AlpineCard>
          <Box p={5}>
            <Box mb={2}>
              <Typography variant="h4" className={classes.headerContainer}>Create an Account</Typography>
            </Box>
            <Formik
              initialValues={{ email: '', password: '', repeatPassword: '' }}
              onSubmit={(values) => authStore.register(values.email, values.password, () => { history.push('/setup-account'); })}
              validationSchema={RegisterForm}
            >
              {
                ({
                  values, errors, handleChange, handleSubmit,
                }) => (
                  <Form>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        id="email"
                        label="Email"
                        type="email"
                        autoComplete="current-email"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email}
                        helperText={errors.email}
                      />
                      <TextField
                        fullWidth
                        required
                        id="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.password}
                        error={errors.password}
                        helperText={errors.password}
                      />
                      <TextField
                        fullWidth
                        required
                        id="repeatPassword"
                        label="Repeat Password"
                        type="password"
                        margin="normal"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.repeatPassword}
                        error={errors.repeatPassword}
                        helperText={errors.repeatPassword}
                        onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                      />
                    </Box>
                    <Box mb={2}>
                      <Button fullWidth variant="contained" className={classes.buttonContainer} onClick={handleSubmit}>
                        Create Account
                      </Button>
                    </Box>
                    {
                      authStore.loginFailure
                        ? <Box textAlign="left">{errorMessage}</Box> : ''
                    }
                    <Box mb={2}>
                      <Divider />
                    </Box>
                    <Box>
                      <Button fullWidth variant="outlined" className={classes.buttonContainer} onClick={() => history.push('/login')}>
                        Sign In
                      </Button>
                    </Box>
                  </Form>
                )
              }
            </Formik>
          </Box>
        </AlpineCard>
      </Box>
    </Box>
  );
};

export default observer(Register);
