import {
  Box, createTheme, Stack, ThemeProvider, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Allura',
    ].join(','),
  },
});

const Signature = ({
  signatureImageUrl, signedByName, signedAt, ...rest
}) => (
  signatureImageUrl
    ? (
      <Stack direction="column">
        <Box className="signature-container">
          <img src={signatureImageUrl} height="50px" alt={signedByName} />
        </Box>
        <Box>
          <Typography
            variant="caption"
          >
            <em>
              {signedByName}
              {' '}
              {format(new Date(signedAt), 'MM-dd-yyyy')}
            </em>
          </Typography>
        </Box>
      </Stack>
    )
    : (
      <ThemeProvider theme={theme}>
        <Typography {...rest}>
          {signedByName}
        </Typography>
      </ThemeProvider>
    )
);

export default Signature;
