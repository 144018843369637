import {
  Box, Divider, Link, Toolbar, Typography,
} from '@mui/material';
import React from 'react';

const Footer = () => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      bottom: 0,
      borderTop: '1px solid #8f91941c',
      width: '100%',
      background: 'white',
      zIndex: 999,
    }}
  >
    <Box className="hide-print">
      <Toolbar>
        <Box
          width="100%"
        >
          <Link href="https://ticketworx.io" underline="none">
            <Typography variant="caption">© 2022 Build IT Solutions, LLC</Typography>
          </Link>
          {' · '}
          <Link href="https://google.com" underline="none">
            <Typography variant="caption">Privacy</Typography>
          </Link>
          {' · '}
          <Link href="https://google.com" underline="none">
            <Typography variant="caption">Terms</Typography>
          </Link>
          {' · '}
          <Link href="https://google.com" underline="none">
            <Typography variant="caption">Sitemap</Typography>
          </Link>
          {' · '}
          <Link href="https://google.com" underline="none">
            <Typography variant="caption">Contact Us</Typography>
          </Link>
        </Box>
      </Toolbar>
    </Box>
    <Box className="show-print">
      <Typography variant="caption">
        <i>Powered by Build IT Solutions</i>
      </Typography>
    </Box>

    <Divider />
  </Box>
);

export default Footer;
