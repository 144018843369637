import {
  Box, Dialog, IconButton, Stack, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ClearIcon from '@mui/icons-material/Clear';
import AlpineButton from '../../../components/AlpineButton';
import createTicketImage from '../../../services/ticket_images/CreateTicketImage';
import deleteTicketImage from '../../../services/ticket_images/DeleteTicketImage';
import fetchTicketImages from '../../../services/ticket_images/FetchTicketImages';

const styles = makeStyles(() => ({
  iconButton: {
    backgroundColor: 'lightgrey',
  },
}));

const ImageAttachmentForm = ({ ticket }) => {
  const [cameraOpen, setCameraOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [stagedPhotoUri, setStagedPhotoUri] = useState(null);
  const [stagedPhoto, setStagedPhoto] = useState(null);

  const classes = styles();

  const handleTakePhoto = (dataUri) => {
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataUri.split(',')[1]);
    else byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    setStagedPhotoUri(dataUri);
    setStagedPhoto(new Blob([ia], { type: mimeString }));
  };

  const handleSaveImage = () => {
    setImageUploading(true);
    createTicketImage(ticket.id, stagedPhoto, (response) => {
      setCameraOpen(false);
      setStagedPhotoUri(null);
      setStagedPhoto(null);
      setImageUploading(false);
      setFileUploading(false);
      setImages(images.concat([response.data]));
    });
  };

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setStagedPhoto(file);
    setStagedPhotoUri(base64);
  };

  const populateImages = () => {
    fetchTicketImages(ticket.id, (response) => {
      setImages(response.data);
    });
  };

  const handleDelete = (imageId) => {
    deleteTicketImage(imageId, () => {
      setImages(images.filter((image) => parseInt(image.id, 10) !== parseInt(imageId, 10)));
    });
  };

  useEffect(() => {
    populateImages();
  }, []);

  return (
    <Box>
      <Box mb={1}>
        <Typography variant="h6">Images</Typography>
      </Box>
      <Stack direction="row" spacing={2} mb={2}>
        {
          images.map((image) => (
            <Box>
              <Box textAlign="right" mb="-1.3rem" mr="-1rem">
                <IconButton
                  onClick={() => handleDelete(image.id)}
                  className={classes.iconButton}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Box>
              <img width="200px" src={image.attributes.s3_image_url} alt="ticket" />
            </Box>
          ))
        }
      </Stack>
      <Stack direction="row" spacing={2}>
        <AlpineButton onClick={() => setCameraOpen(true)} variant="outlined">
          Take Photo
        </AlpineButton>
        <AlpineButton onClick={() => setFileUploading(true)} variant="outlined">
          Upload File
        </AlpineButton>
      </Stack>
      <Dialog
        open={cameraOpen}
        onClose={() => setCameraOpen(false)}
      >
        {stagedPhoto
          ? (
            <Box>
              <img src={stagedPhotoUri} alt="stagedphoto" />
              <Stack direction="column">
                <Box p={2}>
                  <AlpineButton
                    fullWidth
                    onClick={() => handleSaveImage()}
                    variant="contained"
                    loading={imageUploading}
                    loadingText="Image uploading"
                  >
                    Keep
                  </AlpineButton>
                </Box>
                <Box p={2}>
                  <AlpineButton fullWidth onClick={() => setStagedPhoto(null)} variant="outlined">Retake</AlpineButton>
                </Box>
                <Box p={2}>
                  <AlpineButton
                    fullWidth
                    onClick={() => { setCameraOpen(false); setStagedPhoto(null); }}
                  >
                    Cancel
                  </AlpineButton>
                </Box>
              </Stack>
            </Box>
          )
          : (
            <Camera
              onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
            />
          )}
      </Dialog>
      <Dialog
        open={fileUploading}
        onClose={() => setFileUploading(false)}
      >
        {stagedPhoto
          ? (
            <Box>
              <img src={stagedPhotoUri} width="300px" alt="stagedphoto" />
              <Stack direction="column">
                <Box p={2}>
                  <AlpineButton
                    fullWidth
                    onClick={() => handleSaveImage()}
                    variant="contained"
                    loading={imageUploading}
                    loadingText="Image uploading"
                  >
                    Keep
                  </AlpineButton>
                </Box>
                <Box p={2}>
                  <AlpineButton fullWidth onClick={() => setStagedPhoto(null)} variant="outlined">Choose new file</AlpineButton>
                </Box>
                <Box p={2}>
                  <AlpineButton
                    fullWidth
                    onClick={() => { setFileUploading(false); }}
                  >
                    Cancel
                  </AlpineButton>
                </Box>
              </Stack>
            </Box>
          )
          : (
            <Box p={2}>
              <Typography variant="h6">Upload a file</Typography>
              <input
                type="file"
                name="myImage"
                onChange={(event) => {
                  handleFileRead(event);
                }}
              />
            </Box>
          )}
      </Dialog>
    </Box>
  );
};

export default ImageAttachmentForm;
