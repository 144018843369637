import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

const WushAlert = ({ alertStore }) => {
  const handleClose = () => {
    alertStore.clearAlertMessage();
  };

  return (
    <Snackbar
      open={alertStore.alertMessage}
      autoHideDuration={4000}
      onClose={handleClose}
      sx={{ height: '100%' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert onClose={handleClose} severity={alertStore.alertType} sx={{ width: '100%', padding: 4 }}>
        {alertStore.alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default observer(WushAlert);
