import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import AlpineButton from '../../components/AlpineButton';
import SimpleDataDisplay from '../../components/displays/SimpleDataDisplay';
import fetchWorkflow from '../../services/workflows/FetchWorkflow';
import WorkflowStep from './components/WorkflowStep';

const ViewWorkflow = () => {
  const [workflow, setWorkflow] = useState({});
  const [loading, setLoading] = useState(true);

  const match = useRouteMatch();
  const { id } = match.params;

  const history = useHistory();

  useEffect(() => {
    fetchWorkflow(id, (response) => {
      setWorkflow(response.data.attributes);
      setLoading(false);
    });
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <SimpleDataDisplay label="Workflow" value={workflow.name} />
      <SimpleDataDisplay label="Ticket Template" value={workflow.ticket_template} />
      <Box>
        <Typography variant="h6">Steps:</Typography>
        <Box>
          {
            workflow.ordered_workflow_steps.map((step, index) => (
              <Box mb={2}>
                <WorkflowStep workflowStep={step} stepNumber={index + 1} />
              </Box>
            ))
          }
        </Box>
      </Box>
      <Box>
        <AlpineButton onClick={() => history.goBack()}>
          Back
        </AlpineButton>
      </Box>
    </Box>
  );
};

export default ViewWorkflow;
