import React, { useState, useEffect } from 'react';

import {
  Box, Chip, IconButton, Link, Skeleton, Stack, Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import fetchTickets from '../../services/tickets/FetchTickets';
import AlpineDataGrid from '../../components/AlpineDataGrid';
import AuthStore from '../../stores/AuthStore';
import DeleteTicketDialog from './components/DeleteTicketDialog';

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketsLoading, setTicketsLoading] = useState(true);
  const [deleteTicketOpen, setDeleteTicketOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const history = useHistory();

  const authStore = new AuthStore();

  const populateTickets = () => {
    fetchTickets((response) => {
      setTickets(response.data);
      setTicketsLoading(false);
    });
  };

  useEffect(() => {
    populateTickets();
  }, []);

  if (ticketsLoading) {
    return (
      <Box>
        <Skeleton widht="100%" height="400px" />
      </Box>
    );
  }

  return (
    <Box>
      <Box p={2}>
        <Stack my={1} direction="row" spacing={3}>
          <Typography variant="h5">
            Tickets (
            {tickets.length}
            )
          </Typography>
          <IconButton onClick={populateTickets}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Box>
          <AlpineDataGrid
            rows={tickets.map((ticket) => ({
              project_name: ticket.attributes.project_name,
              id: ticket.id,
              read_only: ticket.attributes.read_only,
              deletable: ticket.attributes.deletable,
              description: ticket.attributes.description,
              created_by: ticket.attributes.created_by_name,
              assigned_to: `${ticket.attributes.assigned_to_name} (${ticket.attributes.assigned_to_email})`,
              current_step_name: ticket.attributes.completed_at ? 'Complete' : ticket.attributes.current_step_name,
              created_at: ticket.attributes.created_at ? format(new Date(ticket.attributes.created_at), 'M/dd HH:mm') : 'N/A',
              completed_at: ticket.attributes.completed_at ? format(new Date(ticket.attributes.completed_at), 'M/dd HH:mm') : 'In progress',
              updated_at: ticket.attributes.updated_at ? format(new Date(ticket.attributes.updated_at), 'M/dd HH:mm') : 'In progress',
            }))}
            columns={[
              {
                field: 'id',
                headerName: '#',
                width: 50,
                renderCell: (params) => (
                  // eslint-disable-next-line
                  (params.row.read_only && !authStore.canViewMoney) ? <Typography>{params.value}</Typography>
                  // eslint-disable-next-line
                    : <Link href={`/tickets/${params.row.id}/edit`}><Typography>{params.value}</Typography></Link>
                ),
              },
              { field: 'project_name', headerName: 'Project', width: 200 },
              { field: 'description', headerName: 'Description', width: 200 },
              { field: 'created_by', headerName: 'Created By', width: 200 },
              { field: 'assigned_to', headerName: 'Assigned To', width: 200 },
              { field: 'created_at', headerName: 'Created At', width: 100 },
              { field: 'completed_at', headerName: 'Completed At', width: 100 },
              {
                field: 'current_step_name',
                headerName: 'Status',
                width: 200,
                renderCell: (params) => (
                  // eslint-disable-next-line
                  <Chip color={params.row.completed_at !== 'In progress' ? 'success' : 'default'} size="small" label={params.value} />
                ),
              },
              {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: (params) => (
                  // eslint-disable-next-line
                  <Stack direction="row">
                    <IconButton onClick={() => history.push(`/tickets/${params.row.id}?print=true`)}>
                      <PictureAsPdfIcon />
                    </IconButton>
                    {/* eslint-disable-next-line */}
                    {!params.row.read_only && (
                    <IconButton onClick={() => history.push(`/tickets/${params.row.id}/edit`)}>
                      <EditIcon />
                    </IconButton>
                    )}
                    {/* eslint-disable-next-line */}
                    {(params.row.deletable || authStore.isAdmin) && (
                    <IconButton onClick={() => {
                      setDeleteTicketOpen(true);
                      setSelectedTicket(params.row);
                    }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    )}
                  </Stack>

                ),
              },
            ]}
            // onRowClick={(row) => history.push(`/tickets/${row.id}/edit`)}
          />
        </Box>
      </Box>
      <DeleteTicketDialog
        open={deleteTicketOpen}
        ticket={selectedTicket}
        handleClose={() => {
          setSelectedTicket({});
          setDeleteTicketOpen(false);
        }}
        onSuccess={() => {
          populateTickets();
          setDeleteTicketOpen(false);
        }}
      />
    </Box>
  );
};

export default Tickets;
