import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';

const AlpineButton = ({
  loading = false, loadingText, disabled, children, ...rest
}) => (
  <Button disabled={loading || disabled} {...rest}>
    {loading ? (
      <Box>
        {loadingText}
        {' '}
        <CircularProgress size="20" />
      </Box>
    ) : children}
  </Button>
);

export default AlpineButton;
