import {
  Box, Grid, Typography, Button,
} from '@mui/material';
import React from 'react';
import BackgroundImage from '../../assets/images/jumbo_background.png';
import Stock1 from '../../assets/images/stock1.png';
import Stock2 from '../../assets/images/stock2.png';
import MarketingCard from './components/MarketingCard';
import Cube from '../../components/icons/Cube';
import Activity from '../../components/icons/Activity';
import Bargraph from '../../components/icons/Bargraph';

const Public = () => (
  <Box>
    <Box position="absolute" top={0} zIndex={-999} m="auto">
      <img src={BackgroundImage} width="100%" alt="header" height="800px" />
    </Box>
    <Box pt={10}>
      <Box px="15%" py="8%">
        <Grid container>
          <Grid item sm={6}>
            <Box textAlign="left" mb={10}>
              <Typography variant="h1" fontSize="50px" fontWeight={600}>
                {'Consectetur adipiscing elit. Nunc vulputate libero et'.toUpperCase()}
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography variant="body" fontSize="16px" fontWeight={600}>
                Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
              </Typography>
            </Box>
            <Box pr={5}>
              <Grid container>
                <Grid item sm={4} textAlign="center">
                  <Box>
                    <Typography color="accent" fontSize="36px">
                      90%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="accent" fontSize="16px" fontWeight={400}>
                      Lorem Ipsum
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} textAlign="center">
                  <Box>
                    <Typography color="accent" fontSize="36px">
                      90%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="accent" fontSize="16px" fontWeight={400}>
                      Lorem Ipsum
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} textAlign="center">
                  <Box>
                    <Typography color="accent" fontSize="36px">
                      90%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="accent" fontSize="16px" fontWeight={400}>
                      Lorem Ipsum
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box />
          </Grid>
        </Grid>
      </Box>
      <Box py={10} px="15%" py="8%">
        <Box textAlign="center" mb={5}>
          <Box>
            <Typography variant="highlight" fontSize="24px" fontWeight="600">
              Features
            </Typography>
          </Box>
          <Box>
            <Typography variant="h1" fontSize="50px" fontWeight={600}>
              {'What we do?'.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Grid container mb={5}>
          <Grid item sm={6}>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Cube />} />
            </Box>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Activity />} />
            </Box>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Bargraph />} />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box>
              <Box pl={10}>
                <img src={Stock1} width="100%" alt="stock1" />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Box>
              <Box pr={10}>
                <img src={Stock2} width="100%" alt="stock2" />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Cube />} />
            </Box>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Activity />} />
            </Box>
            <Box p={2}>
              <MarketingCard title="Jorem ipsum dolor sit amet" text="Consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis." icon={<Bargraph />} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mb={6}>
        <Box
          px={{ xs: '5%', sm: '30%' }}
          py={5}
          textAlign="center"
          sx={{ background: 'linear-gradient(270deg, #1E7CD4 -9.93%, #2769BF 50.16%, #1E7CD4 106.49%)' }}
        >
          <Box>
            <Typography fontSize="48px" color="#fff">
              Get started with TicketWorx
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography fontSize="18px" color="#fff">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem
            </Typography>
          </Box>
          <Box>
            <Button color="white" variant="contained">
              Signup
            </Button>
          </Box>
        </Box>
      </Box>
      <Box py={10} px="15%" py="8%">
        <Box textAlign="center" mb={5}>
          <Box>
            <Typography variant="highlight" fontSize="24px" fontWeight="600">
              Testimonials
            </Typography>
          </Box>
          <Box>
            <Typography variant="h1" fontSize="50px" fontWeight={600}>
              {'What they say about us'.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Public;
