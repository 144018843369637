

export const ACCOUNT_TYPES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  SHARED: "shared",
  FOREMAN: "foreman",
  PROJECT_MANAGER: "project_manager",
  SUPER_INTENDENT: "super_intendent",
  SUBCONTRACTOR_PROJECT_MANAGER: "subcontractor_project_manager",
  CONTRACTOR_SUPERINTENDENT: "contractor_superintendent",
  CONTRACTOR_PROJECT_MANAGER: "contractor_project_manager"
}

export const MONEY_ACCOUNTS = [
  ACCOUNT_TYPES.ADMIN, ACCOUNT_TYPES.SHARED, ACCOUNT_TYPES.PROJECT_MANAGER, ACCOUNT_TYPES.SUBCONTRACTOR_PROJECT_MANAGER,
  ACCOUNT_TYPES.CONTRACTOR_PROJECT_MANAGER, ACCOUNT_TYPES.SUPER_ADMIN
]