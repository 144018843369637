import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import fetchWorkflow from '../../../services/workflows/FetchWorkflow';

const TicketTimeline = ({ ticket }) => {
  const [workflow, setWorkflow] = useState({});
  const [workflowLoading, setWorkflowLoading] = useState(true);

  useEffect(() => {
    fetchWorkflow(ticket.attributes.workflow_id, (response) => {
      setWorkflow(response.data);
      setWorkflowLoading(false);
    });
  }, []);

  if (workflowLoading) return <CircularProgress />;

  const activeStep = workflow.attributes.ordered_workflow_steps.findIndex((workflowStep) => (
    workflowStep.id === ticket.attributes.current_step_workflow_step_id
  ));

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Box mb={2}>
        <Typography variant="h6">{workflow.attributes.name}</Typography>
      </Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {workflow.attributes.ordered_workflow_steps.map((step) => (
          <Step key={step.id}>
            <StepLabel
              optional={<Typography variant="caption" />}
            >
              {step.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TicketTimeline;
