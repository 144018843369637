import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useState, useEffect } from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchLaborTypes from '../../../services/labor_types/FetchLaborTypes';
import AuthStore from '../../../stores/AuthStore';
import moneyFormat from '../../../services/MoneyFormat';
import createLaborType from '../../../services/labor_types/CreateLaborType';

const emptyLabor = {
  labor_type_id: null, worker_count: 0, hours: 0, hourly_rate: 0,
};

const calculateLaborRow = (laborRow) => (
  Math.round(laborRow.worker_count * laborRow.hours * laborRow.hourly_rate * 100) / 100
);

const calculateLaborSubtotal = (laborTypes) => (
  laborTypes.reduce((total, laborType) => total + calculateLaborRow(laborType), 0)
);

const LaborSection = ({ formik, readOnly, alertStore }) => {
  const [laborTypes, setLaborTypes] = useState([]);

  const authStore = new AuthStore();

  const populateLaborTypes = (callback) => {
    fetchLaborTypes((response) => {
      setLaborTypes(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateLaborTypes();
  }, []);

  const handleCreate = (laborType) => {
    createLaborType({ name: laborType }, (response) => {
      populateLaborTypes(() => {
        formik.setFieldValue(`ticket_labor_types.${formik.values.ticket_labor_types.length - 1}.labor_type_id`, response.data.id);
      });
      alertStore.setAlertMessage('Labor type added!');
    });
  };

  const getLaboryTypeHourlyRate = (laborTypeId) => {
    if (!laborTypeId || laborTypes.length === 0) return 0;
    return laborTypes.filter((material) => (
      material.attributes.id === parseInt(laborTypeId, 10)
    ))[0].attributes.hourly_rate.cents / 100;
  };

  return (
    <Box id="labor">
      <FieldArray
        name="ticket_labor_types"
        render={
          (arrayHelpers) => (
            <Grid container width="100%">
              <Grid item md={12} mb={1} width="100%">
                <Typography variant="h6">Labor</Typography>
              </Grid>
              {
                formik.values.ticket_labor_types.map((labor, index) => (
                  <>
                    <Grid item md={3} width="100%" mb={2}>
                      <MuiFormikAutocomplete
                        name={`ticket_labor_types.${index}.labor_type_id`}
                        label="Labor Type"
                        formik={formik}
                        options={laborTypes}
                        freeSolo
                        onCreate={(name) => handleCreate(name)}
                        value={labor.labor_type_id}
                        onChange={(laborType) => {
                          if (laborType) {
                            formik.setFieldValue(
                              `ticket_labor_types.${index}.hourly_rate`,
                              getLaboryTypeHourlyRate(laborType.attributes.id),
                            );
                          }
                        }}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_labor_types.${index}.worker_count`}
                        value={formik.values.ticket_labor_types[index].worker_count}
                        label="Workers"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_labor_types.${index}.hours`}
                        value={formik.values.ticket_labor_types[index].hours}
                        label="Hours"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      {authStore.canViewMoney && (
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_labor_types.${index}.hourly_rate`}
                        value={formik.values.ticket_labor_types[index].hourly_rate}
                        label="$/hr"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                      )}
                    </Grid>
                    <Grid item sm={1} width="100%" mb={2}>
                      {authStore.canViewMoney && (
                      <Box>
                        <Typography variant="body1">
                          Total Cost
                        </Typography>
                        <Typography variant="body1">
                          {moneyFormat(calculateLaborRow(labor))}
                        </Typography>
                      </Box>
                      )}
                    </Grid>
                    <Grid item md={2} width="100%">
                      {!formik.values.ticket_labor_types[index]?.id && (
                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                      )}
                    </Grid>
                    <Grid item md={5} />
                    <Grid item md={12} mb={2} />
                  </>
                ))
              }
              {!readOnly && (
              <Grid item md={3} mt={1} width="100%" mb={2}>
                <AlpineButton
                  variant="contained"
                  fullWidth
                  startIcon={<ControlPointIcon />}
                  size="small"
                  onClick={() => arrayHelpers.push(emptyLabor)}
                >
                  Add Labor
                </AlpineButton>
              </Grid>
              )}
            </Grid>
          )
        }
      />
    </Box>
  );
};

export default LaborSection;
export { emptyLabor, calculateLaborRow, calculateLaborSubtotal };
