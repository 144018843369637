import {
  Box, Dialog, Stack, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import uncompleteTicketWorkflowStep from '../../../services/ticketWorkflowSteps/UncompleteTicketWorkflowStep';

const ReviseTicketDialog = ({ open, handleClose, ticket }) => {
  const [note, setNote] = useState('');

  const history = useHistory();

  const handleSubmit = () => {
    uncompleteTicketWorkflowStep(ticket.current_step_id, note, () => {
      history.push('/tickets');
    });
  };

  return (
    <Dialog
      open={open}
    >
      <Box p={2}>
        <Box>
          <Typography variant="h6">
            Are you sure you want to send this ticket back?
          </Typography>
        </Box>
        <Box my={2}>
          <Box>
            <Typography>Provide any additional context</Typography>
          </Box>
          <Box>
            <TextField
              label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="small"
              fullWidth
            />
          </Box>
        </Box>
        <Box>
          <Stack direction="row" spacing={2}>
            <AlpineButton variant="contained" onClick={handleSubmit}>
              Confirm
            </AlpineButton>
            <AlpineButton onClick={handleClose}>
              Cancel
            </AlpineButton>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ReviseTicketDialog;
