import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import fetchEquipmentItems from '../../services/equipment_items/FetchEquipmentItems';
import updateEquipmentItem from '../../services/equipment_items/UpdateEquipmentItem';
import AlpineButton from '../../components/AlpineButton';
import createEquipmentItem from '../../services/equipment_items/CreateEquipmentItem';
import deleteEquipmentItem from '../../services/equipment_items/DeleteEquipmentItem';

const EquipmentItems = () => {
  const [equipmentItems, setEquipmentItems] = useState([]);

  const populateEquipmentItems = (callback) => {
    fetchEquipmentItems((response) => {
      setEquipmentItems(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateEquipmentItems();
  }, []);

  const handleUpdate = (values) => {
    const updateParams = {};
    updateParams[values.field] = values.value;
    updateEquipmentItem(values.id, updateParams, populateEquipmentItems);
  };

  const handleCreate = () => {
    createEquipmentItem({
      name: 'new item',
    }, (response) => {
      setEquipmentItems([response.data].concat(equipmentItems));
    });
  };

  const handleDelete = (id) => {
    deleteEquipmentItem(id, populateEquipmentItems);
  };

  if (!equipmentItems) return <CircularProgress />;

  const equipmentItemRows = equipmentItems.map((equipmentItem) => ({
    id: equipmentItem.id,
    name: equipmentItem.attributes.name,
    description: equipmentItem.attributes.description,
    hourly_rate: equipmentItem.attributes.hourly_rate.cents / 100,
  }));

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      editable: true,
    },
    {
      field: 'hourly_rate',
      headerName: 'Rate',
      width: 150,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <AlpineButton
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.id)}
        >
          Delete
        </AlpineButton>
      ),
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">
          Equipment Items
        </Typography>
      </Box>
      <Box mb={2}>
        <AlpineButton onClick={handleCreate} variant="contained" startIcon={<ControlPointIcon />}>
          Add Equipment Item
        </AlpineButton>
      </Box>
      <Box height="500px">
        <DataGrid
          rows={equipmentItemRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={handleUpdate}
        />
      </Box>

    </Box>
  );
};

export default EquipmentItems;
