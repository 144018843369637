import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useHistory } from 'react-router';
import RequestService from '../../services/RequestService';
import config from '../../config';
import AlpineButton from '../../components/AlpineButton';
import Tickets from '../tickets/Tickets';

const Home = ({ authStore }) => {
  const history = useHistory();

  const fetchCurrentUser = () => {
    RequestService.get(`${config.backendUrl}/authenticated-health`);
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <Box>
      <Grid container width="100%">
        <Grid item md={12} textAlign="right" width="100%">
          <Box textAlign="left">
            <Typography variant="h5">
              Welcome
              {' '}
              {authStore.user?.first_name}
              !
            </Typography>
          </Box>
          <Box textAlign="right">
            <AlpineButton onClick={() => history.push('/tickets/new')} variant="contained" startIcon={<ControlPointIcon />}>
              Create a Ticket
            </AlpineButton>
          </Box>

        </Grid>
        <Grid item md={12} width="100%">
          <Box>
            <Tickets />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(Home);
