import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router';
import AlpineButton from '../../components/AlpineButton';
import fetchWorkflows from '../../services/workflows/FetchWorkflows';

const Workflows = () => {
  const [workflows, setWorkflows] = useState([]);

  const history = useHistory();

  const populateWorkflows = () => {
    fetchWorkflows((response) => {
      setWorkflows(response.data);
    });
  };

  useEffect(() => {
    populateWorkflows();
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Box>
          <Typography variant="h6">
            This page is currently under construction.
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            You will be able to view and then edit workflows from this page.
            {' '}
            For now, you can view workflows and see the ordered steps and information
            {' '}
            about each step.
          </Typography>
        </Box>
      </Box>
      {workflows.map((workflow) => (
        <Box>
          <AlpineButton variant="outlined" onClick={() => history.push(`/workflows/${workflow.id}`)}>
            {workflow.attributes.id}
            {' - '}
            {workflow.attributes.name}
          </AlpineButton>
        </Box>
      ))}
      <Box>
        <AlpineButton onClick={() => history.push('/home')}>
          Cancel
        </AlpineButton>
      </Box>
    </Box>
  );
};

export default Workflows;
