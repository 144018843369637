import {
  Box, CircularProgress, Divider, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { useHistory, useRouteMatch } from 'react-router';
import fetchTicketByUuid from '../../services/tickets/FetchTicketByUuid';
import PrintableTicketView from './components/PrintableTicketView';
import AlpineButton from '../../components/AlpineButton';

const mappedTicket = (ticket) => ({
  ...ticket.attributes,
  ticket_materials: ticket.attributes.ticket_materials.map((ticket_material) => ({
    cost_per_unit: ticket_material.cost_per_unit_cents / 100,
    ...ticket_material,
  })),
  // eslint-disable-next-line
  ticket_equipment_items: ticket.attributes.ticket_equipment_items.map((ticket_equipment_item) => ({
    hourly_rate: ticket_equipment_item.hourly_rate_cents / 100,
    ...ticket_equipment_item,
  })),
  ticket_labor_types: ticket.attributes.ticket_labor_types.map((ticket_labor_type) => ({
    hourly_rate: ticket_labor_type.hourly_rate_cents / 100,
    ...ticket_labor_type,
  })),
  id: ticket.id,
});

const ViewTicket = ({ alertStore }) => {
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const match = useRouteMatch();
  const { uuid } = match.params;

  const history = useHistory();

  useEffect(() => {
    fetchTicketByUuid(uuid, (response) => {
      setTicket(mappedTicket(response.data));
      setLoading(false);
    }, () => {
      alertStore.setAlertMessage('This link is no longer valid. The ticket has either been approved or does not exist.', 'error');
      history.push('/login');
    });
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box textAlign="right" className="hide-print">
        <AlpineButton size="small" onClick={() => window.print()} variant="contained" startIcon={<PrintIcon />}>
          Print Ticket
        </AlpineButton>
      </Box>
      <Box textAlign="center" mb={3}>
        <img src={ticket.organization_logo_url} alt="logo" />
      </Box>
      <Box textAlign="right" className="show-print">
        <Typography variant="h4">{ticket.id}</Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="h5">
          Time and Material Ticket
        </Typography>
      </Box>
      <Box mb={5}>
        <Divider />
      </Box>
      <PrintableTicketView ticket={ticket} />
    </Box>
  );
};

export default ViewTicket;
