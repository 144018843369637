import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import fetchMaterials from '../../services/materials/FetchMaterials';
import updateMaterial from '../../services/materials/UpdateMaterial';
import AlpineButton from '../../components/AlpineButton';
import createMaterial from '../../services/materials/CreateMaterial';
import deleteMaterial from '../../services/materials/DeleteMaterial';

const Materials = () => {
  const [materials, setMaterials] = useState([]);

  const populateMaterials = (callback) => {
    fetchMaterials((response) => {
      setMaterials(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateMaterials();
  }, []);

  const handleUpdate = (values) => {
    const updateParams = {};
    updateParams[values.field] = values.value;
    updateMaterial(values.id, updateParams, populateMaterials);
  };

  const handleCreate = () => {
    createMaterial({
      name: 'new item',
    }, (response) => {
      setMaterials([response.data].concat(materials));
    });
  };

  const handleDelete = (id) => {
    deleteMaterial(id, populateMaterials);
  };

  if (!materials) return <CircularProgress />;

  const materialRows = materials.map((material) => ({
    id: material.id,
    name: material.attributes.name,
    description: material.attributes.description,
    unit: material.attributes.unit,
    cost_per_unit: material.attributes.cost_per_unit.cents / 100,
  }));

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      editable: true,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 150,
      editable: true,
    },
    {
      field: 'cost_per_unit',
      headerName: 'Cost per unit',
      width: 150,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <AlpineButton
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.id)}
        >
          Delete
        </AlpineButton>
      ),
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">
          Materials
        </Typography>
      </Box>
      <Box mb={2}>
        <AlpineButton onClick={handleCreate} variant="contained" startIcon={<ControlPointIcon />}>
          Add Material
        </AlpineButton>
      </Box>
      <Box height="500px">
        <DataGrid
          rows={materialRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={handleUpdate}
        />
      </Box>

    </Box>
  );
};

export default Materials;
