import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchEquipmentItems from '../../../services/equipment_items/FetchEquipmentItems';
import AlpineButton from '../../../components/AlpineButton';
import fetchMaterials from '../../../services/materials/FetchMaterials';

const emptyMaterial = {
  material_id: null, material_name: '', count: 0,
};

const WorklogMaterialSection = ({ formik, readOnly = false }) => {
  const [materials, setMaterials] = useState([]);

  const populateMaterials = (callback) => {
    fetchMaterials((response) => {
      setMaterials(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateMaterials();
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h6">
          Materials
        </Typography>
      </Box>
      <Box>
        <FieldArray
          name="worklog_materials"
          render={
            (arrayHelpers) => (
              <Grid container width="100%">
                {
                  formik.values.worklog_materials.map((labor, index) => (
                    <>
                      <Grid item md={3} width="100%" mb={2}>
                        <MuiFormikAutocomplete
                          name={`worklog_materials.${index}.equipment_item_id`}
                          label="Material Name"
                          formik={formik}
                          options={materials}
                          freeSolo
                          value={labor.equipment_item_id}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid paddingX={1} item md={3} width="100%" mb={2}>
                        <MuiFormikTextField
                          name={`worklog_materials.${index}.count`}
                          label="Units"
                          type="number"
                          formik={formik}
                          options={materials}
                          value={labor.hours}
                          disabled={readOnly}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={2} width="100%">
                        {!formik.values.worklog_materials[index]?.id && (
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                        )}
                      </Grid>
                      <Grid item md={4} />
                    </>
                  ))
                }
                {!readOnly && (
                  <Grid item md={3} mt={1} width="100%" mb={2}>
                    <AlpineButton
                      variant="contained"
                      fullWidth
                      startIcon={<ControlPointIcon />}
                      size="small"
                      onClick={() => arrayHelpers.push(emptyMaterial)}
                    >
                      Add Material
                    </AlpineButton>
                  </Grid>
                )}
              </Grid>
            )
          }
        />
      </Box>
    </Box>
  );
};

export default WorklogMaterialSection;
export { emptyMaterial };
