import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import SimpleDataDisplay from '../../../components/displays/SimpleDataDisplay';
import fetchOrganization from '../../../services/organizations/FetchOrganization';

const ViewOrganization = () => {
  const [organization, setOrganization] = useState(null);

  const history = useHistory();

  const match = useRouteMatch();
  const { id } = match.params;

  const populateOrganization = () => {
    fetchOrganization(id, (response) => {
      setOrganization(response.data);
    });
  };

  useEffect(() => {
    populateOrganization();
  }, []);

  if (!organization) return <CircularProgress />;

  return (
    <Box>
      <Box mb={2}>
        <AlpineButton onClick={() => history.goBack()}>
          Back
        </AlpineButton>
      </Box>
      <Box>
        <Typography variant="h5">
          {organization.attributes.name}
        </Typography>
      </Box>
      <Box>
        <SimpleDataDisplay label="User count" value={organization.attributes.users.length} />
      </Box>
    </Box>
  );
};

export default ViewOrganization;
