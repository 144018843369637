import { Box, Stack } from '@mui/material';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import AlpineButton from '../../../components/AlpineButton';

const SignHere = ({ onEnd, sigRef }) => (
  <Box>
    <Box>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: 'sigCanvas' }}
        onEnd={onEnd}
        ref={sigRef}
      />
    </Box>
    <Stack direction="row" spacing={1}>
      <AlpineButton
        onClick={() => sigRef.current.clear()}
      >
        Clear Signature
      </AlpineButton>
    </Stack>
  </Box>
);

export default SignHere;
