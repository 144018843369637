import { Box } from '@mui/material';
import React from 'react';
import AccountCard from './components/AccountCard';

const Account = () => (
  <Box>
    <AccountCard />
  </Box>
);

export default Account;
