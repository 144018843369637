import {
  Box, CircularProgress, Divider, TextField, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

import { useRouteMatch } from 'react-router';
import PrintIcon from '@mui/icons-material/Print';
import AlpineButton from '../../components/AlpineButton';
import fetchTicket from '../../services/tickets/FetchTicket';
import sendEmail from '../../services/tickets/SendEmail';
import PrintableTicketView from './components/PrintableTicketView';

const mappedTicket = (ticket) => ({
  ...ticket.attributes,
  ticket_materials: ticket.attributes.ticket_materials.map((ticket_material) => ({
    cost_per_unit: ticket_material.cost_per_unit_cents / 100,
    ...ticket_material,
  })),
  // eslint-disable-next-line
  ticket_equipment_items: ticket.attributes.ticket_equipment_items.map((ticket_equipment_item) => ({
    hourly_rate: ticket_equipment_item.hourly_rate_cents / 100,
    ...ticket_equipment_item,
  })),
  ticket_labor_types: ticket.attributes.ticket_labor_types.map((ticket_labor_type) => ({
    hourly_rate: ticket_labor_type.hourly_rate_cents / 100,
    ...ticket_labor_type,
  })),
});

const ViewTicket = ({ alertStore }) => {
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(null);
  const match = useRouteMatch();
  const { id } = match.params;

  useEffect(() => {
    fetchTicket(id, (response) => {
      setTicket(mappedTicket(response.data));
      setLoading(false);
    });
  }, []);

  const handleSendEmail = () => {
    sendEmail(id, email, () => {
      alertStore.setAlertMessage('Successfully sent email!');
    }, () => {
      alertStore.setAlertMessage('Failed to send email', 'error');
    });
  };

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box textAlign="right" className="hide-print">
        <AlpineButton size="small" onClick={() => window.print()} variant="contained" startIcon={<PrintIcon />}>
          Print Ticket
        </AlpineButton>
      </Box>
      <Box textAlign="center" mb={3}>
        <img src={ticket.organization_logo_url} alt="logo" />
      </Box>
      <Box textAlign="right" className="show-print">
        <Typography variant="h4">{id}</Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="h5">
          Time and Material Ticket
        </Typography>
      </Box>
      {ticket.publicly_accessible && (
      <Box className="hide-print">
        <Box textAlign="center">
          <Typography variant="body1">
            https://ticketworx.io/x-auth/tickets/
            {ticket.current_step_uuid}
          </Typography>
        </Box>
        <Box textAlign="center" mt={2} mb={1}>
          <TextField
            style={{ width: '350px' }}
            label="Email address"
            size="small"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box textAlign="center" mb={3}>
          <AlpineButton disabled={!email || email.length < 5} onClick={handleSendEmail} style={{ width: '350px' }} variant="contained">
            Send Link
          </AlpineButton>
        </Box>
      </Box>
      )}
      <Box mb={5}>
        <Divider />
      </Box>
      <PrintableTicketView ticket={ticket} />
    </Box>
  );
};

export default ViewTicket;
