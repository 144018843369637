/* eslint-disable */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Login from './containers/login/Login';
import Register from './containers/login/Register';
import Home from './containers/home/Home';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import AuthenticatedRoute from './components/routes/AuthenticatedRoute';
import HomeSidebar from './containers/header/HomeSidebar';
import Public from './containers/public/Public';
import Account from './containers/account/Account';
import PrivacyPolicy from './containers/privacyPolicy/PrivacyPolicy';
import ResetPassword from './containers/login/ResetPassword';
import ViewWorkflow from './containers/workflows/ViewWorkflow';
import NewTicket from './containers/tickets/NewTicket';
import ViewTicket from './containers/tickets/ViewTicket';
import EditTicket from './containers/tickets/EditTicket';
import PublicViewTicket from './containers/tickets/PublicViewTicket';
import Settings from './containers/settings/Settings';
import Workflows from './containers/workflows/Workflows';
import Users from './containers/superAdmin/Users';
import Organizations from './containers/superAdmin/organizations/Organizations';
import ViewOrganization from './containers/superAdmin/organizations/ViewOrganization';
import EquipmentItems from './containers/admin/EquipmentItems';
import LaborTypes from './containers/admin/LaborTypes';
import Materials from './containers/admin/Materials';
import NewWorklog from './containers/worklogs/NewWorklog';

const UnauthenticatedRoutes = {
  home: '/home',
};

const Routes = ({ childProps }) => console.log(childProps.authStore) || (
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Public} props={childProps} />
    <UnauthenticatedRoute path="/privacy" exact component={PrivacyPolicy} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/register" exact component={Register} props={childProps} />
    <UnauthenticatedRoute path="/reset-password" exact component={ResetPassword} props={childProps} />

    <UnauthenticatedRoute path="/x-auth/tickets/:uuid" exact component={PublicViewTicket} props={childProps} />

    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    
    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />

    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />

    <AuthenticatedRoute path="/workflows" exact component={Workflows} props={childProps} />
    <AuthenticatedRoute path="/workflows/:id" exact component={ViewWorkflow} props={childProps} />

    <AuthenticatedRoute path="/tickets/new" exact component={NewTicket} props={childProps} />
    <AuthenticatedRoute path="/tickets/:id/edit" exact component={EditTicket} props={childProps} />
    <AuthenticatedRoute path="/tickets/:id" exact component={ViewTicket} props={childProps} />

    <AuthenticatedRoute path="/worklogs/new" exact component={NewWorklog} props={childProps} />

    <AuthenticatedRoute path="/equipment-items" exact component={EquipmentItems} props={childProps} />
    <AuthenticatedRoute path="/labor-types" exact component={LaborTypes} props={childProps} />
    <AuthenticatedRoute path="/materials" exact component={Materials} props={childProps} />

    <AuthenticatedRoute path="/users" exact component={Users} props={childProps} />
    <AuthenticatedRoute path="/organizations" exact component={Organizations} props={childProps} />
    <AuthenticatedRoute path="/organizations/:id" exact component={ViewOrganization} props={childProps} />

    <Redirect to="/home" />
  </Switch>
);

Routes.propTypes = {
  childProps: PropTypes.object,
};

export { UnauthenticatedRoutes };
export default Routes;
/* eslint-enable */
