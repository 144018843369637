import {
  Box, Grid, Typography, IconButton,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useState, useEffect } from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikAutocomplete from '../../../components/MuiFormikAutocomplete';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import fetchEquipmentItems from '../../../services/equipment_items/FetchEquipmentItems';
import AuthStore from '../../../stores/AuthStore';
import moneyFormat from '../../../services/MoneyFormat';
import createEquipmentItem from '../../../services/equipment_items/CreateEquipmentItem';

const emptyEquipment = {
  equipment_item_id: null, equipmentName: '', hours: 0, hourly_rate: 0, count: 0,
};

const calculateEquipmentRow = (equipmentRow) => (
  Math.round(equipmentRow.count * equipmentRow.hours * equipmentRow.hourly_rate * 100) / 100
);

const calculateEquipmentSubsection = (equipmentItems) => (
  equipmentItems.reduce((total, equipmentItem) => total + calculateEquipmentRow(equipmentItem), 0)
);

const EquipmentSection = ({ formik, readOnly, alertStore }) => {
  const [equipmentItems, setEquipmentItems] = useState([]);

  const authStore = new AuthStore();

  const populateEquipmentItems = (callback) => {
    fetchEquipmentItems((response) => {
      setEquipmentItems(response.data);
      if (callback) callback();
    });
  };

  useEffect(() => {
    populateEquipmentItems();
  }, []);

  const handleCreate = (equipmentItem) => {
    createEquipmentItem({ name: equipmentItem }, (response) => {
      populateEquipmentItems(() => {
        formik.setFieldValue(`ticket_equipment_items.${formik.values.ticket_equipment_items.length - 1}.equipment_item_id`, response.data.id);
      });
      alertStore.setAlertMessage('Equipment item added!');
    });
  };

  const getEquipmentItemHourlyRate = (equipment_item_id) => {
    if (!equipment_item_id) return 0;
    return equipmentItems.filter((material) => (
      material.attributes.id === parseInt(equipment_item_id, 10)
    ))[0].attributes.hourly_rate.cents / 100;
  };

  return (
    <Box id="equipment">
      <FieldArray
        name="ticket_equipment_items"
        render={
          (arrayHelpers) => (
            <Grid container>
              <Grid item md={12} mb={1}>
                <Typography variant="h6">Equipment</Typography>
              </Grid>
              {
                formik.values.ticket_equipment_items.map((equipment, index) => (
                  <>
                    <Grid item md={3} width="100%" mb={2}>
                      <MuiFormikAutocomplete
                        name={`ticket_equipment_items.${index}.equipment_item_id`}
                        label="Equipment"
                        formik={formik}
                        options={equipmentItems}
                        value={formik.values.ticket_equipment_items[index].equipment_item_id}
                        disabled={readOnly}
                        freeSolo
                        onCreate={(name) => handleCreate(name)}
                        onChange={(equipmentItem) => {
                          if (equipmentItem) {
                            formik.setFieldValue(
                              `ticket_equipment_items.${index}.hourly_rate`,
                              getEquipmentItemHourlyRate(equipmentItem.attributes.id),
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_equipment_items.${index}.count`}
                        value={formik.values.ticket_equipment_items[index].count}
                        label="Count"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_equipment_items.${index}.hours`}
                        value={formik.values.ticket_equipment_items[index].hours}
                        label="Days"
                        type="number"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={1} paddingX={1} width="100%" mb={2}>
                      {authStore.canViewMoney && (
                      <MuiFormikTextField
                        formik={formik}
                        name={`ticket_equipment_items.${index}.hourly_rate`}
                        label="$/day"
                        type="number"
                        size="small"
                        value={formik.values.ticket_equipment_items[index].hourly_rate}
                        disabled={readOnly}
                        fullWidth
                      />
                      )}
                    </Grid>
                    <Grid item sm={1} width="100%" mb={2}>
                      {authStore.canViewMoney && (
                      <Box>
                        <Typography variant="body1">
                          Total Cost
                        </Typography>
                        <Typography variant="body1">
                          {moneyFormat(calculateEquipmentRow(equipment))}
                        </Typography>
                      </Box>
                      )}
                    </Grid>
                    <Grid item md={2} width="100%" mb={2}>
                      {!formik.values.ticket_labor_types[index]?.id && (
                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                      )}
                    </Grid>
                    <Grid item md={4} />
                    <Grid item md={12} mb={2} />
                  </>
                ))
              }
              {!readOnly && (
              <Grid item md={3} mt={1} width="100%">
                <AlpineButton
                  variant="contained"
                  fullWidth
                  startIcon={<ControlPointIcon />}
                  size="small"
                  onClick={() => arrayHelpers.push(emptyEquipment)}
                >
                  Add Equipment
                </AlpineButton>
              </Grid>
              )}

            </Grid>
          )
        }
      />
    </Box>
  );
};

export default EquipmentSection;
export { emptyEquipment, calculateEquipmentRow, calculateEquipmentSubsection };
