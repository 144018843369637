import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router';
import AlpineButton from '../../../components/AlpineButton';
import AlpineCard from '../../../components/AlpineCard';
import fetchOrganizations from '../../../services/organizations/FetchOrganizations';

const Organizations = ({ authStore }) => {
  const [organizations, setOrganizations] = useState([]);

  const populateOrganizations = () => {
    fetchOrganizations((response) => {
      setOrganizations(response.data);
    });
  };

  useEffect(() => {
    populateOrganizations();
  }, []);

  const history = useHistory();

  if (!authStore.isSuperAdmin) {
    history.push('/home');
  }

  return (
    <Box>
      {organizations.map((organization) => (
        <AlpineCard>
          <Box>
            <Box>
              <Typography variant="h6">
                {organization.attributes.name}
              </Typography>
            </Box>
            <AlpineButton onClick={() => history.push(`/organizations/${organization.id}`)}>
              View
            </AlpineButton>
          </Box>
        </AlpineCard>
      ))}
    </Box>
  );
};

export default Organizations;
