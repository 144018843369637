import { Box, Dialog, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import AlpineButton from '../../../components/AlpineButton';
import MuiFormikTextField from '../../../components/MuiFormikTextField';
import createProject from '../../../services/projects/CreateProject';

const AddProjectDialog = ({ open, onClose, onSuccess }) => {
  const handleSubmit = (values) => {
    createProject(values, onSuccess);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box p={2}>
        <Formik
          initialValues={{
            name: '',
            description: '',
          }}
          onSubmit={handleSubmit}
        >
          {
            (formik) => (
              <Box>
                <Box mb={2} mx={5}>
                  <Typography variant="h6">Create a new Project</Typography>
                </Box>
                <Box mb={2}>
                  <MuiFormikTextField
                    formik={formik}
                    value={formik.values.name}
                    name="name"
                    label="Project name"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <MuiFormikTextField
                    formik={formik}
                    value={formik.values.description}
                    name="description"
                    label="Project description"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <AlpineButton variant="contained" fullWidth onClick={formik.handleSubmit}>
                    Create
                  </AlpineButton>
                </Box>
                <Box>
                  <AlpineButton fullWidth onClick={onClose}>
                    Cancel
                  </AlpineButton>
                </Box>
              </Box>
            )
          }
        </Formik>
      </Box>
    </Dialog>
  );
};

export default AddProjectDialog;
